<template>
<v-row align="start" justify="start" class="pt-5">
  <v-col cols="12" class="d-flex align-start justify-start" v-if="isUserCompanyAdmin">
    <v-col cols="8" lg="3" md="4" sm="5" class="pt-0 px-0">
      <account-select v-model="selectedAccounts" :api-key="apiKey"></account-select>
    </v-col>
  </v-col>
  <v-col cols="4" lg="2">
    {{$t('user_notifications.broken_item.low_priority')}}
  </v-col>
  <v-col cols="8" lg="10" class="ltr d-flex" :class="{'justify-end': isRtl}">
    <v-switch class="mt-0"
      :disabled="allDisabled"
      v-model="notifications.lowPriority.medium"
      false-value="null"
      true-value="sms">
    </v-switch>
  </v-col>
  <v-col cols="4" lg="2">
    {{$t('user_notifications.broken_item.high_priority')}}
  </v-col>
  <v-col cols="8" lg="10" class="ltr d-flex" :class="{'justify-end': isRtl}">
    <v-switch class="mt-0"
      :disabled="allDisabled"
      v-model="notifications.highPriority.medium"
      false-value="null"
      true-value="sms">
    </v-switch>
  </v-col>
  <v-col cols="4" lg="2">
    {{$t('user_notifications.broken_item.deadline')}}
  </v-col>
  <v-col cols="8" lg="10" class="ltr d-flex" :class="{'justify-end': isRtl}">
    <v-switch class="mt-0"
      :disabled="allDisabled"
      v-model="notifications.deadline.medium"
      false-value="null"
      true-value="sms">
    </v-switch>
  </v-col>
  <v-col cols="4" lg="2">
    {{$t('user_notifications.broken_item.notify_not_only_assigned')}}
  </v-col>
  <v-col cols="8" lg="10" class="ltr d-flex" :class="{'justify-end': isRtl}">
    <v-switch class="mt-0"
      :disabled="allDisabled"
      v-model="notifications.notifyLevelAll">
    </v-switch>
  </v-col>
  <v-col cols="4" lg="2">
    {{$t('user_notifications.broken_item.chat_notification')}}
  </v-col>
  <v-col cols="8" lg="10" class="ltr d-flex pb-0" :class="{'justify-end': isRtl}">
    <v-switch class="mt-0"
      :disabled="allDisabled"
      v-model="notifications.chatNotification.medium"
      false-value="null"
      true-value="sms">
    </v-switch>
  </v-col>
  <v-col cols="4" lg="2" class="pa-0" classs="pb-10 mb-10">
  </v-col>
  <v-col cols="8" lg="10" class="pt-0 pb-10 mb-10">
    <v-radio-group v-model="notifications.chatNotification.type" row :disabled="chatNotificationDisabled || allDisabled" class="d-inline-block">
      <v-radio :label="$t('all')" value="all" ></v-radio>
      <v-radio :label="$t('user_notifications.broken_item.chat_notification_user_mentioned')" value="user_mentioned"></v-radio>
    </v-radio-group>
  </v-col>
  <v-col cols="12" class="mb-10 mb-sm-0">
    <v-footer padless fixed class="save-notifications">
      <v-col cols="12" class="text-right">
        <v-btn color="blue darken-1" depressed tile @click="save" :loading="isLoading" :disabled="isLoading">
          <v-icon v-if="showNotificationsSaved">
            check
          </v-icon>
          <span v-else>
            {{$t('save')}}
          </span>
        </v-btn>
      </v-col>
    </v-footer>
  </v-col>
</v-row>

</template>
<script>
import AccountSelect from '@/components/shared/AccountSelect.vue'
import useAuth from '@/composables/useAuth.js'

import useUserNotification from '@/composables/useUserNotification.js'
import { isRtl } from '@/i18n.js'
import { isObject } from '@/helpers/Utils.js'

import { ref, computed } from 'vue'

const NOTIFIED_MODEL = 'BrokenItem'
const brokenItemPriorities = { low: '1', high: '2' }
export default {
  components: {
    'account-select': AccountSelect
  },
  props: {
    apiKey: {
      type: String,
      required: true
    }
  },
  setup (props) {
    const { isUserCompanyAdmin, isUserAccountEmployee } = useAuth()
    const {
      loadNotifications, saveNotifications, isLoading, mediumSmsDisabled
    } = useUserNotification(props.apiKey)
    const showNotificationsSaved = ref(false)
    const selectedAccounts = ref([])

    const notifications = ref({
      lowPriority: { medium: 'null' },
      highPriority: { medium: 'null' },
      deadline: { medium: 'null' },
      notifyLevelAll: false,
      chatNotification: { medium: 'null', type: 'all' }
    })

    loadNotifications([NOTIFIED_MODEL]).then(({ reponseByModel, apiKeys }) => {
      const notificationsForModel = reponseByModel[NOTIFIED_MODEL]
      if (Array.isArray(notificationsForModel) !== true) return
      if (isUserCompanyAdmin.value === true) {
        const selectedAccountsVal = selectedAccounts.value
        selectedAccountsVal.splice(0, selectedAccountsVal.length)
        apiKeys.forEach((apiKey) => selectedAccountsVal.push(apiKey))
      }

      const notificationsVal = notifications.value
      const lowPriority = notificationsForModel.find((notification) => notification.field_name === 'priority_id' && notification.field_value === brokenItemPriorities.low)
      const highPriority = notificationsForModel.find((notification) => notification.field_name === 'priority_id' && notification.field_value === brokenItemPriorities.high)
      const deadline = notificationsForModel.find((notification) => notification.field_name === 'deadline' && notification.field_value === 'deadline_day')
      const messagesNotification = notificationsForModel.find((notification) => notification.field_name === 'messages')
      const shouldNotifyAll = notificationsForModel.find((notification) => notification.field_name === 'notify_level' && notification.field_value === 'notify_all')
      if (isObject(lowPriority) === true) {
        notificationsVal.lowPriority.medium = lowPriority.medium
      }
      if (isObject(highPriority) === true) {
        notificationsVal.highPriority.medium = highPriority.medium
      }

      if (isObject(deadline) === true) {
        notificationsVal.deadline.medium = deadline.medium
      }

      if (isObject(messagesNotification) === true) {
        notificationsVal.chatNotification.medium = messagesNotification.medium
        notificationsVal.chatNotification.type = messagesNotification.field_value
      }
      if (isObject(shouldNotifyAll) === true) {
        notificationsVal.notifyLevelAll = true
      }
    })

    // Methods
    const save = () => {
      isLoading.value = true

      const notificationsToSave = { scopes: {} }
      notificationsToSave.scopes[NOTIFIED_MODEL] = [
        { field_name: 'priority_id', field_value: brokenItemPriorities.low },
        { field_name: 'priority_id', field_value: brokenItemPriorities.high },
        { field_name: 'deadline', field_value: 'deadline_day' },
        { field_name: 'messages', field_value: 'all' },
        { field_name: 'messages', field_value: 'user_mentioned' },
        { field_name: 'notify_level', field_value: 'notify_all' }
      ]
      const apiKeys = isUserCompanyAdmin.value === true ? selectedAccounts.value : [props.apiKey]

      notificationsToSave[NOTIFIED_MODEL] = []
      const notificationsVal = notifications.value
      if (notificationsVal.lowPriority.medium !== 'null') {
        notificationsToSave[NOTIFIED_MODEL].push({ field_name: 'priority_id', field_value: brokenItemPriorities.low, medium: notificationsVal.lowPriority.medium })
      }
      if (notificationsVal.highPriority.medium !== 'null') {
        notificationsToSave[NOTIFIED_MODEL].push({ field_name: 'priority_id', field_value: brokenItemPriorities.high, medium: notificationsVal.highPriority.medium })
      }
      if (notificationsVal.deadline.medium !== 'null') {
        notificationsToSave[NOTIFIED_MODEL].push({ field_name: 'deadline', field_value: 'deadline_day', medium: notificationsVal.deadline.medium })
      }
      if (notificationsVal.chatNotification.medium !== 'null') {
        notificationsToSave[NOTIFIED_MODEL].push({ field_name: 'messages', field_value: notificationsVal.chatNotification.type, medium: notificationsVal.chatNotification.medium })
      }
      if (notificationsVal.notifyLevelAll === true) {
        notificationsToSave[NOTIFIED_MODEL].push({ field_name: 'notify_level', field_value: 'notify_all', medium: 'sms' })
      }
      saveNotifications({ params: notificationsToSave, apiKeys })
        .then(() => {
          showNotificationsSaved.value = true
          setTimeout(() => (showNotificationsSaved.value = false), 3000)
        })
        .finally(() => (isLoading.value = false))
    }

    // Computed
    const chatNotificationDisabled = computed(() => notifications.value.chatNotification.medium === 'null')
    const allDisabled = computed(() => isUserCompanyAdmin.value === true && selectedAccounts.value.length === 0)
    return {
      isLoading,
      notifications,
      mediumSmsDisabled,
      chatNotificationDisabled,
      showNotificationsSaved,
      isUserAccountEmployee,
      isUserCompanyAdmin,
      selectedAccounts,
      allDisabled,
      isRtl,
      save
    }
  }
}
</script>
