import { index as indexCrossAccountOperation, indexSingleCrossAccountOperationsByDay } from '@/api_client/CrossAccountOperationSchedule.js'
import { destroy as CrossAccountOperationDelete } from '@/api_client/CrossAccountOperation.js'
import { totalCompanyTasks } from '@/api_client/CompanyStats.js'

import useAccount from '@/composables/useAccount.js'
import UseOperationSchedule from '@/composables/UseOperationSchedule.js'

import { isString, isDate } from '@/helpers/Utils.js'

import {
  isDateInTheFuture, isDateIsToday, dateToISO8601String, isValidISO8601Date,
  getClientDateFormatted, ISO8601StringToDate, timeStringToUserFormat, isDateInThePast
} from '@/helpers/DateTime.js'

import { ref, computed } from 'vue'

const DEFAULT_PER_PAGE_FOR_TOTALS = 100
const DEFAULT_PAGE = 1

const defaultFilters = () => ({ accountApiKeys: [], myOperations: false })
const defaultStats = () => ({ tasksCount: 0, finishedTasksCount: 0 })

const isLoading = ref(false)
const isLoadingTasks = ref(false)
const clientDate = ref(new Date())
const crossAccountOperationsTotals = ref([])
const crossAccountOperationPagination = ref({ page: DEFAULT_PAGE, per_page: DEFAULT_PER_PAGE_FOR_TOTALS, total: 0 })
const totalCrossAccountOperationPages = ref(1)
const activeCrossAccountTaskId = ref(null)
const crossAccountTasks = ref([])
const filters = ref(defaultFilters)
const stats = ref(defaultStats())

let currentApiKey = null
let countryCode = null
let localeCode = null


export default function useCrossAccountDailyTask(apiKey) {
  const resetValues = () => {
    clientDate.value = new Date()
    crossAccountOperationsTotals.value = []
    isLoading.value = false
    crossAccountOperationPagination.value = { page: DEFAULT_PAGE, per_page: DEFAULT_PER_PAGE_FOR_TOTALS, total: 0 }
    totalCrossAccountOperationPages.value = 1
    filters.value = defaultFilters()
    activeCrossAccountTaskId.value = null
    crossAccountTasks.value = []
    stats.value = defaultStats()
  }

  if (isString(apiKey) && currentApiKey !== apiKey) {
    resetValues()
    const { accountCountryCode: accountCountryCodeRef, accountLocaleCode: accountLocaleCodeRef } = useAccount()

    currentApiKey = apiKey
    countryCode = accountCountryCodeRef.value
    localeCode = accountLocaleCodeRef.value
  }

  const loadTasksStats = async () => {
    const requestParams = {
      client_date: clientDateIso.value,
      client_date_is_today: isDateIsToday(clientDate.value),
      client_date_is_future: isDateInTheFuture(clientDate.value)
    }
    return totalCompanyTasks(currentApiKey, requestParams)
      .then(({ data: { data } }) => {
        const statValue = stats.value
        statValue.tasksCount = data.cross_account_tasks_count
        statValue.finishedTasksCount = data.finished_cross_account_tasks_count
      })
  }

  const setCrossAccountOperationsPagingation = (paginationData) => {
    crossAccountOperationPagination.value.total = paginationData.total
    crossAccountOperationPagination.value.page = paginationData.page
    const total = paginationData.total || 0
    if (total === 0 || total <= crossAccountOperationPagination.value.per_page) {
      totalCrossAccountOperationPages.value = 0
    } else {
      totalCrossAccountOperationPages.value = Math.ceil(total / crossAccountOperationPagination.value.per_page)
    }
  }

  const addFormatsToOperation = (operation) => {
    const endDate = operation.end_date
    const startTime = operation.start_time

    if (endDate && isValidISO8601Date(endDate)) {
      operation.endDateUserFormat = getClientDateFormatted({ fromDate: ISO8601StringToDate(endDate), countryCode })
    }
    if (isString(startTime)) {
      operation.startTimeUserFormat = timeStringToUserFormat({ timeString: startTime, localeCode })
    }
  }

  const setFirstTaskAsActive = (forceLoad = false) => {
    if (crossAccountOperationsTotals.value.length === 0) return
    onCrossAccountClick(crossAccountOperationsTotals.value[0], forceLoad)
  }

  const baseRequestsParams = () => {
    return {
      client_date: clientDateIso.value,
      filters: totalOperationsByDayFilter()
    }
  }
  const totalOperationsByDayFilter = () => {
    const result = {}
    if (Array.isArray(filters.value.accountApiKeys) && filters.value.accountApiKeys.length > 0) {
      result.account_api_keys = filters.value.accountApiKeys
    }
    if (filters.value.myOperations === true) {
      result.my_operations = true
    }
    return result
  }

  const resetAndReload = () => {
    crossAccountOperationsTotals.value = []
    crossAccountOperationPagination.value = { page: DEFAULT_PAGE, per_page: DEFAULT_PER_PAGE_FOR_TOTALS, total: 0 }
    totalCrossAccountOperationPages.value = 1
    return loadCrossAccountDailyOperations()
  }

  const changeStatsCompleteTask = (offset) => {
    const totalTasksReported = stats.value.finishedTasksCount
    const totalTasks = stats.value.tasksCount
    if (offset === -1 && totalTasksReported > 0 && totalTasks > 0) {
      stats.value.finishedTasksCount -= 1
    }
    else if (offset === 1 && totalTasks > totalTasksReported && totalTasks > 0) {
      stats.value.finishedTasksCount += 1
    }
  }

  // Methods

  const changeTotalReportedOperations = ({ crossAccountOperationId, offset }) => {
    const caoIndex = crossAccountOperationsTotals.value.findIndex((operation) => operation.cross_account_operation_id === crossAccountOperationId)
    if (caoIndex === -1) return

    const crossAccountOperation = crossAccountOperationsTotals.value[caoIndex]
    const newTotalTasksReported = crossAccountOperation.total_tasks_reported + offset
    const totalTasks = crossAccountOperation.total_tasks
    if (newTotalTasksReported < 0 || totalTasks < newTotalTasksReported) return
    crossAccountOperation.total_tasks_reported = newTotalTasksReported
    changeStatsCompleteTask(offset)
  }

  const addTaskFormat = (task) => {
    const reportedDate = task.report_date
    const reportedTime = task.report_time
    if (isValidISO8601Date(reportedDate)) {
      task.reportedDate = getClientDateFormatted({ fromDate: ISO8601StringToDate(reportedDate), countryCode })
    }
    if (isString(reportedTime)) {
      task.reportedTime = timeStringToUserFormat({ timeString: reportedTime, localeCode })
    }
  }

  const loadSingleCrossAccountOperation = () => {
    const requestParams = baseRequestsParams()
    return indexSingleCrossAccountOperationsByDay(currentApiKey, activeCrossAccountTaskId.value, requestParams)
      .then((response) => {
        crossAccountTasks.value = response.data.data.map(task => {
          addTaskFormat(task)
          return new UseOperationSchedule(task)
        })
      })
  }

  const loadCrossAccountDailyOperations = () => {
    isLoading.value = true
    const isClientDateIsToday = isDateIsToday(clientDate.value)
    const isClientDateIsInTheFuture = isDateInTheFuture(clientDate.value)
    const requestParams = Object.assign(baseRequestsParams(), {
      client_date_is_today: isClientDateIsToday,
      client_date_is_future: isClientDateIsInTheFuture,
      page: crossAccountOperationPagination.value.page,
      per_page: crossAccountOperationPagination.value.per_page
    })
    return indexCrossAccountOperation(currentApiKey, requestParams)
      .then((response) => {
        setCrossAccountOperationsPagingation(response.data.meta)
        const crossAccountOperationsTotalsVal = crossAccountOperationsTotals.value
        response.data.data.forEach(operation => {
          addFormatsToOperation(operation)
          crossAccountOperationsTotalsVal.push(operation)
        })
      }).finally(() => (isLoading.value = false))
  }

  const onDateChange = (newDate) => {
    clientDate.value = newDate
    reloadPageData()
  }

  const resetFiltersForTotalOperations = () => {
    filters.value = defaultFilters()
    reloadPageData()
  }

  const loadNextPage = () => {
    if (isLoading.value) return
    isLoading.value = true
    crossAccountOperationPagination.value.page += 1
    return loadCrossAccountDailyOperations()
  }

  const deleteCrossAccountOperation = (crossAccountOperationId) => {
    isLoading.value = true
    return CrossAccountOperationDelete(currentApiKey, crossAccountOperationId)
      .then(() => {
        const crossAccountOperationsVal = crossAccountOperationsTotals.value
        const index = crossAccountOperationsVal.findIndex(operation => operation.cross_account_operation_id === crossAccountOperationId)
        if (index > -1) {
          crossAccountOperationsVal.splice(index, 1)
        }
        loadTasksStats()
      })
      .finally(() => (isLoading.value = false))
  }

  const onSingleOperationDeleted = async (task) => {
    const isReported = task.isReported()
    await task.deleteOperation()

    const taskId = task.operationRef.operation_id
    const taskIndex = crossAccountTasks.value.findIndex((row) => row.operationRef.operation_id === taskId)
    if (taskIndex === -1) return

    crossAccountTasks.value.splice(taskIndex, 1)

    const caoIndex = crossAccountOperationsTotals.value.findIndex((operation) => operation.cross_account_operation_id === activeCrossAccountTaskId.value)
    if (caoIndex === -1) return
    const crossAccountOperation = crossAccountOperationsTotals.value[caoIndex]
    if (crossAccountOperation.total_tasks === 1) {
      crossAccountOperationsTotals.value.splice(caoIndex, 1)
    } else {
      crossAccountOperation.total_tasks -= 1
      if (isReported && crossAccountOperation.total_tasks_reported > 0) {
        crossAccountOperation.total_tasks_reported -= 1
      }
    }
    if (stats.value.tasksCount > 0) stats.value.tasksCount -= 1
    if (isReported && stats.value.finishedTasksCount > 0) stats.value.finishedTasksCount -= 1
  }

  const onCrossAccountClick = async (crossAccountTask, forceLoad) => {
    if (activeCrossAccountTaskId.value === crossAccountTask.cross_account_operation_id && forceLoad !== true) return
    activeCrossAccountTaskId.value = crossAccountTask.cross_account_operation_id
    isLoadingTasks.value = true
    crossAccountTasks.value = []
    await loadSingleCrossAccountOperation()
    isLoadingTasks.value = false
  }

  const findTask = (taskId) => crossAccountTasks.value.find(task => task.operationRef.operation_id === taskId)

  const getActiveCrossAccountTask = () => crossAccountOperationsTotals.value.find(task => task.cross_account_operation_id === activeCrossAccountTaskId.value)

  // Computed
  const clientDateIso = computed(() => dateToISO8601String(clientDate.value))
  const acitveTask = computed(() => getActiveCrossAccountTask())
  const tasksDisabled = computed(() => {
    if (dateToISO8601String(new Date()) === clientDateIso.value) return false
    const previousDay = new Date()
    previousDay.setDate(previousDay.getDate() - 1)
    if (dateToISO8601String(previousDay) === clientDateIso.value) return false
    if (isDateInThePast(clientDate.value)) return false
    return true
  })

  const hasMoreOperations = computed(() => {
    const crossAccountOperationPaginationVal = crossAccountOperationPagination.value
    const page = crossAccountOperationPaginationVal.page || 0
    const perPage = crossAccountOperationPaginationVal.per_page || 0
    const total = crossAccountOperationPaginationVal.total || 0
    return page * perPage < total
  })

  const reloadPageData = () => Promise.all([loadTasksStats(),
  resetAndReload().then(() => setFirstTaskAsActive(true))])
  const applyFiltersForTotalOperations = reloadPageData
  const initPageData = (clientDateVal) => {
    resetValues()
    if (isDate(clientDateVal)) {
      clientDate.value = clientDateVal
    }
    return Promise.all([loadTasksStats(), loadCrossAccountDailyOperations()])
      .then(setFirstTaskAsActive)
  }


  return {
    clientDate,
    crossAccountOperationsTotals,
    totalCrossAccountOperationPages,
    hasMoreOperations,
    filters,
    crossAccountTasks,
    isLoadingTasks,
    clientDateIso,
    tasksDisabled,
    activeCrossAccountTaskId,
    acitveTask,
    stats,
    loadCrossAccountDailyOperations,
    onDateChange,
    loadNextPage,
    deleteCrossAccountOperation,
    applyFiltersForTotalOperations,
    resetFiltersForTotalOperations,
    onSingleOperationDeleted,
    changeTotalReportedOperations,
    onCrossAccountClick,
    findTask,
    getActiveCrossAccountTask,
    initPageData,
    reloadPageData
  }
}
