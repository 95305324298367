import VueGtag from 'vue-gtag'
import router from '@/router.js'
import Vue from 'vue'

(() => {
  if (import.meta.env.MODE !== 'production') return

  Vue.use(VueGtag, {
    config: { id: 'G-SXHTKWQL65' },
    appName: 'Web App',
    pageTrackerScreenviewEnabled: true
  }, router)
})()
