<template>
  <v-bottom-navigation color="black" grow app class="bottom-nav"
    height="62">
    <v-btn x-small v-if="companyContext"
      :to="{ name: 'company_stores' }">
      <span>{{ $t('bottom_navigation.accounts') }}</span>
      <v-icon color="black">account_circle</v-icon>
    </v-btn>
    <v-btn x-small :to="{ name: 'tasks' }">
      <span>{{ $t('bottom_navigation.tasks') }}</span>
      <v-icon color="black">task_alt</v-icon>
    </v-btn>
    <account-faulty-device-count
      class="v-btn text-center"
      :count-mode="companyContext ? 'company' : 'account'">
      <template v-slot:default="{ faultyDeviceCount }">
        <v-badge
          color="red"
          offset-x="16"
          offset-y="16"
          :value="faultyDeviceCount && faultyDeviceCount > 0"
          :content="faultyDeviceCount"
          overlap>
          <v-btn x-small :to="{ name: brokenItemRoute }">
            <span>{{ $t('bottom_navigation.faulty_device') }}</span>
            <v-icon color="black">assignment_late</v-icon>
          </v-btn>
        </v-badge>
      </template>
    </account-faulty-device-count>
    <v-btn x-small :to="{ name: chatRoute }">
      <span>{{ $t('bottom_navigation.chat') }}</span>
      <app-chat-icon-badge :apiKey="apiKey">
        <v-icon color="black">chat_bubble</v-icon>
      </app-chat-icon-badge>
    </v-btn>
    <v-btn x-small :to="{ name: blogRoute }">
      <span>{{ $t('community') }}</span>
      <v-badge color="red" dot :value="getHaveUnreadPosts" overlap>
        <v-icon color="black">import_contacts</v-icon>
      </v-badge>
    </v-btn>

  </v-bottom-navigation>
</template>
<style lang="scss">
.bottom-nav {
  padding-bottom: 4px;
  padding-top: 2px;
  align-items: flex-end;

  .v-btn.v-btn:not(.v-btn--round).v-size--x-small {
    padding-left: 0;
    padding-right: 0;
    min-width: 0;
    font-size: 11px;

    span.v-btn__content {
      font-weight: 700;
      padding-top: 4px;
    }
  }
}
</style>
<script>
import AccountFaultyDeviceCount from '@/components/shared/AccountFaultyDeviceCount.vue'
import AppChatIconBadge from '@/components/shared/AppChatIconBadge.vue'

import useAuth from '@/composables/useAuth.js'
import useBottomNavigation from '@/composables/useBottomNavigation.js'

import { ref } from 'vue'

import { useRoute } from 'vue-router/composables'
export default {
  components: {
    'account-faulty-device-count': AccountFaultyDeviceCount,
    'app-chat-icon-badge': AppChatIconBadge
  },
  setup() {
    const router = useRoute()
    const { apiKey } = useAuth()
    const { getHaveUnreadPosts, employeesRoute,
      brokenItemRoute, chatRoute, blogRoute, companyContext } =
      useBottomNavigation()

    const selectedTab = ref(router.name)

    return {
      selectedTab,
      brokenItemRoute,
      chatRoute,
      blogRoute,
      employeesRoute,
      companyContext,
      getHaveUnreadPosts,
      apiKey
    }
  }
}
</script>
