<template>
  <v-row align="center" justify="start" no-gutters class="px-4 py-2 single-cao-task pointer texts-start"
    :style="{ 'background-color': operationColor }"
    :class="{ 'selected': selected }"
    @click="onTaskClick">
    <v-col cols="9" class="t-700-16">
      {{ task.operation_description }}
    </v-col>
    <v-col cols="3" class="t-500-13 texts-end color2 ltr">
      {{ task.total_tasks_reported }} / {{ task.total_tasks }}
    </v-col>
    <v-col cols="12">
      <v-icon color="#F5409B">
        schedule
      </v-icon>
      <span class="pps-1 color5 t-400-12">
        {{ frequencyText }}
      </span>
      <span v-if="showStartTime" class="t-700-13 color1 pps-2">
        {{ task.startTimeUserFormat }}
      </span>
      <div class="color5 t-400-12">
        <v-icon color="#F5409B">
          {{ isRtl ? 'west' : 'east' }}
        </v-icon>
        {{ translateShiftPartName(task.shift_part) }}
      </div>
    </v-col>
    <v-col cols="12" v-if="showEveryXWeeksStartPeriod" class="my-1">
      <v-icon color="#F5409B">
        calendar_today
      </v-icon>
      <span class="pps-1 color7 t-400-12">
        {{ startPeriodForEveryXWeeksFrequnecy }}
      </span>
    </v-col>
    <v-col cols="12">
      <v-divider class="my-3"></v-divider>
      <v-chip label color="#0040FF" class="white--text" v-if="showReportTag">
        <span class="material-icons-outlined mme-1">sell</span>
        <span class="t-500-14">{{ translatedReportTag }}</span>
      </v-chip>
    </v-col>
    <v-col cols="12" class="row align-start justify-start no-gutters mt-3">
      <v-col class="flex-grow-1 flex-shrink-0 t-700-13">
        {{ $t('account_operations.created_by', {
          firstName: task.creator_data.first_name, lastName: task.creator_data.last_name
        }) }}
        <v-icon v-if="showGuidanceImages"
          color="#011D35"
          class="mms-3 pointer material-icons-outlined"
          @click="onGuidanceImageClick">
          image
        </v-icon>
        <v-icon v-if="showGuidanceFile"
          class="mms-3 pointer"
          color="#011D35"
          @click="onGuidanceFileClick">
          attach_file
        </v-icon>
      </v-col>
      <v-col class="flex-grow-0 flex-shrink-1">
        <single-cross-account-task-options
          @edit-click="onEditClick"
          @delete-click="onDeleteClick"
          @copy-click="onCopyClick">
        </single-cross-account-task-options>
      </v-col>
    </v-col>
  </v-row>
</template>
<style lang="scss">
.single-cao-task {
  border-radius: 8px;
  border: 1px solid black;
  background: var(--Task-15-azul, rgba(52, 166, 182, 0.15));
  box-shadow: 0px 16px 24px 0px rgba(0, 0, 0, 0.06), 0px 2px 6px 0px rgba(0, 0, 0, 0.04), 0px 0px 1px 0px rgba(0, 0, 0, 0.04);

  &.selected {
    border: 2px solid #0040FF;
  }
}
</style>
<script>


import SingleCrossAccountTaskOptions from '@/components/corss_account_tasks/cross_account_task_list/SingleCrossAccountTaskOptions.vue'

import { translateReportTag, translateShiftPartName } from '@/store/modules/OperationSchedule.js'
import { frequencyTextFromTask, FrequencyTypes, calculateCurrentStartPeriodForEveryXWeeksFrequency } from '@/store/modules/OperationFrequency.js'
import { dayNameFromISO8601String, ISO8601StringToDate, getClientDateFormatted, translateDayFromDate } from '@/helpers/DateTime.js'
import { defaultColor, newDefaultColor } from '@/store/modules/DeviceOperation.js'
import useAccount from '@/composables/useAccount.js'

import { EventBus, TASK_GUIDANCE_IMAGE_CLICK } from '@/EventBus.js'

import { isString, isHexColor } from '@/helpers/Utils.js'

import { i18n, isRtl } from '@/i18n.js'

import { computed } from 'vue'
export default {
  props: {
    task: {
      type: Object,
      required: true
    },
    clientDate: {
      type: String,
      required: true
    },
    selected: {
      type: Boolean,
      default: false
    }
  },
  components: {
    'single-cross-account-task-options': SingleCrossAccountTaskOptions
  },
  setup(props, { emit }) {
    const { accountCountryCode } = useAccount()

    // Methods
    const onGuidanceImageClick = () => EventBus.emit(TASK_GUIDANCE_IMAGE_CLICK, props.task)
    const onGuidanceFileClick = () => window.open(props.task.guidance_file, '_blank').focus()
    const onEditClick = () => emit('edit-click', props.task)
    const onDeleteClick = () => emit('delete-click', props.task)
    const onCopyClick = () => emit('copy-click', props.task)
    const onTaskClick = () => emit('task-click', props.task)

    // Computed
    const showStartTime = computed(() => isString(props.task.startTimeUserFormat))
    const showReportTag = computed(() => isString(props.task.report_tag))
    const translatedReportTag = computed(() => showReportTag.value ? translateReportTag(props.task.report_tag) : '')

    const operationColor = computed(() => {
      const color = props.task.operation_color
      if (color === defaultColor) return newDefaultColor

      if (isHexColor(color)) return color
      return newDefaultColor

    })

    const frequencyText = computed(() => {
      const task = props.task
      const dayName = dayNameFromISO8601String(props.clientDate)
      return frequencyTextFromTask({
        task,
        dateString: task.first_scheduled_date,
        dayName
      })
    })

    const showEveryXWeeksStartPeriod = computed(() => {
      const task = props.task
      if (task.frequency_type !== FrequencyTypes.EveryXWeek) return false

      const firstScheudled = ISO8601StringToDate(task.first_scheduled_date)
      const clientDate = ISO8601StringToDate(props.clientDate)
      const startPeriod = calculateCurrentStartPeriodForEveryXWeeksFrequency(task.frequency, firstScheudled, clientDate)
      return startPeriod.getTime() !== clientDate.getTime()
    })

    const startPeriodForEveryXWeeksFrequnecy = computed(() => {
      const task = props.task
      const firstScheudled = ISO8601StringToDate(task.first_scheduled_date)
      const clientDate = ISO8601StringToDate(props.clientDate)
      const startPeriod = calculateCurrentStartPeriodForEveryXWeeksFrequency(task.frequency, firstScheudled, clientDate)

      return i18n.t('operation_frequency.week_x_frequency_start_period', {
        date: getClientDateFormatted({ fromDate: startPeriod, countryCode: accountCountryCode.value }),
        day: translateDayFromDate(startPeriod)
      })
    })

    const showGuidanceImages = computed(() => {
      const guidanceImages = props.task.guidance_images
      return Array.isArray(guidanceImages) && guidanceImages.length > 0
    })

    const showGuidanceFile = computed(() => {
      const guidanceFileUrl = props.task.guidance_file
      return isString(guidanceFileUrl) && guidanceFileUrl.length > 0
    })
    return {
      frequencyText,
      showStartTime,
      showReportTag,
      showEveryXWeeksStartPeriod,
      startPeriodForEveryXWeeksFrequnecy,
      translatedReportTag,
      showGuidanceImages,
      showGuidanceFile,
      operationColor,
      onGuidanceImageClick,
      onGuidanceFileClick,
      onEditClick,
      onDeleteClick,
      onCopyClick,
      onTaskClick,
      translateShiftPartName,
      isRtl
    }
  }
}
</script>