<template>
  <v-row align="center" justify="center" class="mt-12 mt-sm-5 staff-jobs"
    v-if="apiKeyLoaded"
    :class="{ 'rtl': isRtl, 'ltr': !isRtl }">
    <upsert-staff-account-job ref="staffAccountJob"
      @staff-job-upsert="loadTablePage">
    </upsert-staff-account-job>
    <additional-allowed-executors-dialog ref="additionlExecutors"></additional-allowed-executors-dialog>
    <confirm-delete max-width="300" ref="deleteDialog"></confirm-delete>
    <v-btn fab small color="black white--text" @click="createNewJob"
      class="add-button">
      <v-icon>add</v-icon>
    </v-btn>
    <v-col cols="12" class="page-title">
      {{ $t("org_structure_jobs.staff_page_title") }}
    </v-col>
    <v-col cols="12" md="10" lg="8">
      <v-row align="start" justify="start" no-gutters>
        <v-col cols="12" class="pb-2">
          <span class="font-weight-bold mx-1">
            {{ $t("org_structure_jobs.total_jobs") }}
          </span>
          <span class="font-weight-bold blue--text">
            {{ totalJobs }}
          </span>
        </v-col>
        <v-col cols="12">
          <v-data-table
            :items="jobs"
            hide-default-header
            :items-per-page="DEFAULT_PER_PAGE"
            class="elevation-1"
            :loading="isLoading"
            :loading-text="$t('loading')"
            hide-default-footer
            item-key="id">
            <template v-slot:header>
              <thead class="v-data-table-header">
                <tr>
                  <th>
                    <v-row align="center" justify="start" class="text-center">
                      <v-col cols="12">
                        {{ $t('name') }}
                      </v-col>
                    </v-row>
                  </th>
                </tr>
              </thead>
            </template>
            <template v-slot:item="{ item }">
              <staff-account-job-table-row :job="item"
                @delete-job="deleteJob"
                @edit-job="editJob"
                @open-extra-executors="openExtraExecutors">
              </staff-account-job-table-row>
            </template>
            <template v-slot:no-data>
              <v-alert :value="true" color="white black--text">
                {{ $t("no_result") }}
              </v-alert>
            </template>
          </v-data-table>
        </v-col>
        <v-col cols="12" class="text-center" v-if="totalPages > 0">
          <v-pagination v-model="pagination.page" circle total-visible="10" :length="totalPages"
            v-on:input="pageChange"></v-pagination>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
  <v-row v-else cols="12" align="center" justify="center">
    <v-progress-circular indeterminate color="primary"></v-progress-circular>
  </v-row>
</template>
<style lang="scss">
.staff-jobs {
  padding-top: 60px;

  @media only screen and (max-width: 600px) {
    padding-top: 90px;
  }
}
</style>
<script>

import StaffAccountJobTableRow from '@/components/company_jobs/StaffAccountJobTableRow.vue'
import AdditionalAllowedExecutorsDialog from '@/components/company_jobs/AdditionalAllowedExecutorsDialog.vue'

import useCompany from '@/composables/useCompany.js'
import useAuth from '@/composables/useAuth.js'

import UpsertStaffAccountJob from '@/components/company_jobs/UpsertStaffAccountJob.vue'
import ConfirmDelete from '@/components/shared/ConfirmDelete.vue'

import { index as JobClientIndex, deleteJob as JobClientDeleteJob } from '@/api_client/AccountJob.js'
import { indexByJobIds as UserAllowedJobClientIndexByJobId } from '@/api_client/UserAllowedJob.js'

import { getClientDate } from '@/helpers/DateTime.js'
import { handler as errHandler } from '@/classes/ErrorHandler.js'

import { isString } from '@/helpers/Utils.js'

import { ref, computed, watch } from 'vue'
import { i18n, isRtl } from '@/i18n.js'
const DEFAULT_PER_PAGE = 60
const DEFAULT_PAGE = 1

const singleJob = (job) => ({ id: job.id, name: job.name, allowedExecutors: [] })

const getTotalPages = (pagination) => {
  const total = pagination.total || 0
  if (total === 0 || total <= pagination.per_page) {
    return 0
  }
  return Math.ceil(total / pagination.per_page)
}

export default {
  components: {
    'staff-account-job-table-row': StaffAccountJobTableRow,
    'additional-allowed-executors-dialog': AdditionalAllowedExecutorsDialog,
    'upsert-staff-account-job': UpsertStaffAccountJob,
    'confirm-delete': ConfirmDelete
  },
  setup() {
    const isLoading = ref(false)
    const jobs = ref([])
    const totalPages = ref(0)
    const totalJobs = ref(0)
    const pagination = ref({ page: DEFAULT_PAGE, per_page: DEFAULT_PER_PAGE })
    const staffAccountJob = ref(null)
    const deleteDialog = ref(null)
    const additionlExecutors = ref(null)
    const { apiKey } = useAuth()
    const { staffAccountApiKey } = useCompany(apiKey.value)

    const loadJobs = async () => {
      const requestParams = Object.assign({}, pagination.value, { client_date: getClientDate() })
      const response = await JobClientIndex(staffAccountApiKey.value, requestParams)
      jobs.value = response.data.data.map(singleJob)
      const paginationResponse = response.data.meta
      pagination.value = Object.assign({}, pagination.value, paginationResponse)
      totalPages.value = getTotalPages(pagination.value)
      totalJobs.value = paginationResponse.total
    }

    const loadUserAllowedJobs = async () => {
      if (totalJobs.value === 0) return
      const accountJobIds = jobs.value.map((job) => job.id)
      const response = await UserAllowedJobClientIndexByJobId(staffAccountApiKey.value, accountJobIds)
      const userAllowedJobs = response.data
      jobs.value.forEach((job) => {
        const jobId = job.id
        if (jobId in userAllowedJobs) {
          job.allowedExecutors = userAllowedJobs[jobId].map((user) => `${user.first_name} ${user.last_name}`)
        }
      })
    }
    const loadTablePage = async () => {
      isLoading.value = true
      loadJobs()
        .then(loadUserAllowedJobs)
        .catch(errHandler)
        .finally(() => (isLoading.value = false))
    }
    // Methods
    const pageChange = () => loadTablePage()
    const createNewJob = () => staffAccountJob.value.openDialog()
    const deleteJob = async (jobId) => {
      const title = i18n.t('org_structure_jobs.delete_job_title')
      const content = i18n.t('org_structure_jobs.delete_job_content')
      const shouldDelete = await deleteDialog.value.open({ title, content })
      if (!shouldDelete) return

      isLoading.value = true
      JobClientDeleteJob(staffAccountApiKey.value, jobId)
        .then(() => loadTablePage())
        .catch(errHandler)
        .finally(() => deleteDialog.value.close())
    }

    const editJob = (job) => staffAccountJob.value.openForUpdate({ id: job.id, name: job.name })
    const openExtraExecutors = (job) => additionlExecutors.value.openDialog({ name: job.name, allowedExecutors: job.allowedExecutors })

    const apiKeyLoaded = computed(() => isString(staffAccountApiKey.value) === true &&
      staffAccountApiKey.value.length > 0)
    watch((apiKeyLoaded), (newVal) => {
      if (newVal !== true) return
      loadTablePage()
    }, { immediate: true })
    return {
      DEFAULT_PER_PAGE,
      isLoading,
      jobs,
      totalPages,
      totalJobs,
      pagination,
      apiKeyLoaded,
      staffAccountJob,
      deleteDialog,
      additionlExecutors,
      isRtl,
      pageChange,
      createNewJob,
      loadTablePage,
      editJob,
      deleteJob,
      openExtraExecutors
    }
  }
}
</script>
