import axios from 'axios'

const uploadFile = (file, url) => {
  const options = {
    headers: {
      'Content-Type': file.type
    }
  }
  return axios.put(url, file, options)
}

export {
  uploadFile
}
