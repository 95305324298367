<template>
  <v-row align="start" justify="start" class="pt-5 job-notifications" v-bind:class="{ rtl: isRtl }">
    <v-col cols="12" class="d-flex align-start justify-start" v-if="isUserCompanyAdmin">
      <v-col cols="8" lg="3" md="4" sm="5" class="pt-0 px-0">
        <account-select v-model="selectedAccounts" :api-key="apiKey"></account-select>
      </v-col>
    </v-col>
    <v-col cols="12" class="px-0">
      <v-row align="start" justify="start">
        <v-col cols="4" lg="2" v-if="!isUserCompanyAdmin && isAccountManagerOrAbove">
          {{ $t('user_notifications.job.cross_account_operation_notification') }}
        </v-col>
        <v-col cols="8" lg="10" v-if="!isUserCompanyAdmin && isAccountManagerOrAbove"
          class="ltr d-flex" :class="{ 'justify-end': isRtl }">
          <v-switch class="mt-0"
            :disabled="mediumSmsDisabled"
            v-model="notifications.crossAccountOperations.medium"
            false-value="null"
            true-value="sms">
          </v-switch>
        </v-col>
        <v-col cols="4" lg="2" v-if="isUserAccountEmployee">
          {{ $t('user_notifications.job.task_daily') }}
        </v-col>
        <v-col cols="8" lg="10" v-if="isUserAccountEmployee"
          class="ltr d-flex" :class="{ 'justify-end': isRtl }">
          <v-switch class="mt-0"
            :disabled="mediumSmsDisabled"
            v-model="notifications.dailyTasks.medium"
            false-value="null"
            true-value="sms">
          </v-switch>
        </v-col>
        <v-col cols="4" lg="2" v-if="!isUserCompanyAdmin">
          {{ $t('user_notifications.job.task_create') }}
        </v-col>
        <v-col cols="8" lg="10" v-if="!isUserCompanyAdmin"
          class="ltr d-flex" :class="{ 'justify-end': isRtl }">
          <v-switch class="mt-0"
            :disabled="mediumSmsDisabled"
            v-model="notifications.operationCreate.medium"
            false-value="null"
            true-value="sms">
          </v-switch>
        </v-col>
        <v-col cols="4" lg="2">
          {{ $t('user_notifications.job.chat_notification') }}
        </v-col>
        <v-col cols="8" lg="10" class="ltr d-flex pb-0" :class="{ 'justify-end': isRtl }">
          <v-switch class="mt-0"
            :disabled="mediumSmsDisabled"
            v-model="notifications.chatNotification.medium"
            false-value="null"
            true-value="sms">
          </v-switch>
        </v-col>
        <v-col cols="4" lg="2"></v-col>
        <v-col cols="8" lg="10" class="pa-0">
          <v-radio-group v-model="notifications.chatNotification.type" row
            :disabled="chatNotificationDisabled || allDisabled" class="d-inline-block">
            <v-radio :label="$t('all')" value="all"></v-radio>
            <v-radio :label="$t('user_notifications.job.chat_notification_cross_account')"
              value="cross_account_operations"></v-radio>
          </v-radio-group>
        </v-col>
      </v-row>
    </v-col>
    <v-col cols="12" v-if="isAccountManagerOrAbove && !isUserCompanyAdmin" class="px-0">
      <v-row align="start" justify="start">
        <v-col cols="4" lg="2">
          {{ $t('user_notifications.job.end_of_shift') }}
        </v-col>
        <v-col cols="8" lg="10" class="ltr d-flex pb-0" :class="{ 'justify-end': isRtl }">
          <v-switch class="mt-0"
            :disabled="mediumSmsDisabled"
            v-model="notifications.completedShift.medium"
            false-value="null"
            true-value="sms">
          </v-switch>
        </v-col>
        <v-col cols="8" lg="3" sm="4">
          <job-selection-search class="pt-0 mt-0 job-select"
            v-model="notifications.completedShift.jobIds"
            :disabled="shiftCompletedDisabled"
            :api-key="apiKey"
            multiple
            :label="$t('employees_dialog.choose_jobs_placeholder')">
          </job-selection-search>
        </v-col>
      </v-row>
      <v-row align="start" justify="start" v-if="isAccountAdminOrAbove">
        <v-col cols="4" lg="2">
          {{ $t('user_notifications.job.birthdate_notification') }}
        </v-col>
        <v-col cols="8" lg="10" class="ltr d-flex pb-0" :class="{ 'justify-end': isRtl }">
          <v-switch class="mt-0"
            :disabled="mediumEmaildisabled"
            v-model="notifications.birthdate"
            false-value="null"
            true-value="sms">
          </v-switch>
          <div class="text-caption red--text px-6" v-if="mediumEmaildisabled">
            {{ $t('user_notifications.missing_email') }}
          </div>
        </v-col>
      </v-row>
    </v-col>
    <v-col cols="12" class="mt-10">
      <v-footer padless fixed class="save-notifications">
        <v-col cols="12" class="text-right">
          <v-btn color="blue darken-1" depressed tile @click="save" :loading="isLoading" :disabled="isLoading">
            <v-icon v-if="showNotificationsSaved">
              check
            </v-icon>
            <span v-else>
              {{ $t('save') }}
            </span>
          </v-btn>
        </v-col>
      </v-footer>
    </v-col>
  </v-row>
</template>
<style lang="scss">
.job-notifications {
  &.rtl {
    .job-select {
      .v-select__slot {
        .v-label {
          right: 0 !important;
          left: auto !important;
        }
      }
    }
  }
}
</style>
<script>
import JobSelectionSearch from '@/components/job_selection_search/JobSelectionSearch.vue'
import AccountSelect from '@/components/shared/AccountSelect.vue'

import useUserNotification from '@/composables/useUserNotification.js'
import useAuth from '@/composables/useAuth.js'

import { isRtl } from '@/i18n.js'

import { isObject } from '@/helpers/Utils.js'

import { ref, computed } from 'vue'
const OPERATION_SCHEDULE_MODEL = 'OperationSchedule'
const USER_MODEL = 'User'
const ACCOUNT_JOB_COMPLETED_SHIFT_MODEL = 'AccountJobCompletedShift'

const modelsForRole = (isAccountManagerOrAbove, isUserCompanyAdmin) => {
  if (isAccountManagerOrAbove !== true) return [OPERATION_SCHEDULE_MODEL]
  if (isUserCompanyAdmin === true) return [OPERATION_SCHEDULE_MODEL]

  return [OPERATION_SCHEDULE_MODEL, USER_MODEL, ACCOUNT_JOB_COMPLETED_SHIFT_MODEL]
}
export default {
  props: {
    apiKey: {
      type: String,
      required: true
    }
  },
  components: {
    'job-selection-search': JobSelectionSearch,
    'account-select': AccountSelect
  },
  setup(props) {
    const { isAccountManagerOrAbove, isUserCompanyAdmin, isAccountAdminOrAbove, isUserAccountEmployee } = useAuth()
    const {
      loadNotifications, saveNotifications, isLoading, mediumSmsDisabled, mediumEmaildisabled
    } = useUserNotification(props.apiKey)
    const showNotificationsSaved = ref(false)
    const selectedAccounts = ref([])
    const notifications = ref({
      operationCreate: { medium: 'null' },
      dailyTasks: { medium: 'null' },
      chatNotification: { medium: 'null', type: 'all' },
      crossAccountOperations: { medium: 'null' },
      birthdate: 'null',
      completedShift: { jobIds: [], medium: 'sms' }
    })
    isLoading.value = true
    const models = modelsForRole(isAccountManagerOrAbove.value, isUserCompanyAdmin.value)

    loadNotifications(models).then(({ reponseByModel, apiKeys }) => {
      if (isUserCompanyAdmin.value === true) {
        const selectedAccountsVal = selectedAccounts.value
        selectedAccountsVal.splice(0, selectedAccountsVal.length)
        apiKeys.forEach((apiKey) => selectedAccountsVal.push(apiKey))
      }
      const notificationsForOperationScheduleModel = reponseByModel[OPERATION_SCHEDULE_MODEL] || []
      const chatNotification = notificationsForOperationScheduleModel.find((notification) => notification.field_name === 'messages')
      const operationCreate = notificationsForOperationScheduleModel.find((notification) => notification.field_name === 'operation' && notification.field_value === 'create')
      const dailyTasks = notificationsForOperationScheduleModel.find((notification) => notification.field_name === 'operation' && notification.field_value === 'day_with_operations')
      const notificationsVal = notifications.value

      if (isObject(chatNotification) === true) {
        notificationsVal.chatNotification.medium = chatNotification.medium
        notificationsVal.chatNotification.type = chatNotification.field_value
      }
      if (isObject(operationCreate) === true) {
        notificationsVal.operationCreate.medium = operationCreate.medium
      }
      if (isObject(dailyTasks) === true) {
        notificationsVal.dailyTasks.medium = dailyTasks.medium
      }
      if (isAccountManagerOrAbove.value !== true) return

      const notificationsForUserModel = reponseByModel[USER_MODEL] || []
      const notificationsForAccountJobCompletedShiftModel = reponseByModel[ACCOUNT_JOB_COMPLETED_SHIFT_MODEL] || []

      const crossAccountNotification = notificationsForOperationScheduleModel.find((notification) => notification.field_name === 'cross_account' && notification.field_value === 'create')
      const birthDateNotification = notificationsForUserModel.find((notification) => notification.field_name === 'birthdate' && notification.field_value === 'reminder')
      const shiftCompleteNotification = notificationsForAccountJobCompletedShiftModel.filter((notification) => notification.field_name === 'account_job_id')

      if (isObject(crossAccountNotification) === true) {
        notificationsVal.crossAccountOperations.medium = crossAccountNotification.medium
      }
      if (isObject(birthDateNotification) === true && isAccountAdminOrAbove.value === true) {
        notificationsVal.birthdate = true
      }
      if (shiftCompleteNotification.length > 0) {
        const jobIds = shiftCompleteNotification.map((notification) => parseInt(notification.field_value))
        const medium = shiftCompleteNotification[0].medium
        notificationsVal.completedShift.jobIds = jobIds
        notificationsVal.completedShift.medium = medium
      }
    })
      .finally(() => (isLoading.value = false))

    const saveNotificationsParams = () => {
      const notificationsToSave = { scopes: {} }
      const notificationsVal = notifications.value

      notificationsToSave[OPERATION_SCHEDULE_MODEL] = []
      if (notificationsVal.chatNotification.medium !== 'null') {
        notificationsToSave[OPERATION_SCHEDULE_MODEL].push({ field_name: 'messages', field_value: notificationsVal.chatNotification.type, medium: notificationsVal.chatNotification.medium })
      }
      if (notificationsVal.crossAccountOperations.medium !== 'null') {
        notificationsToSave[OPERATION_SCHEDULE_MODEL].push({ field_name: 'cross_account', field_value: 'create', medium: notificationsVal.crossAccountOperations.medium })
      }
      if (notificationsVal.operationCreate.medium !== 'null') {
        notificationsToSave[OPERATION_SCHEDULE_MODEL].push({ field_name: 'operation', field_value: 'create', medium: notificationsVal.operationCreate.medium })
      }
      if (notificationsVal.dailyTasks.medium !== 'null') {
        notificationsToSave[OPERATION_SCHEDULE_MODEL].push({ field_name: 'operation', field_value: 'day_with_operations', medium: notificationsVal.dailyTasks.medium })
      }

      notificationsToSave.scopes[OPERATION_SCHEDULE_MODEL] = [
        { field_name: 'messages', field_value: 'all' },
        { field_name: 'messages', field_value: 'cross_account_operations' },
        { field_name: 'cross_account', field_value: 'create' },
        { field_name: 'operation', field_value: 'create' },
        { field_name: 'operation', field_value: 'day_with_operations' }
      ]
      if (isAccountManagerOrAbove.value !== true) return notificationsToSave

      if (isUserCompanyAdmin.value === true) return notificationsToSave

      notificationsToSave[USER_MODEL] = []
      notificationsToSave[ACCOUNT_JOB_COMPLETED_SHIFT_MODEL] = []

      notificationsToSave.scopes[USER_MODEL] = [{ field_name: 'birthdate', field_value: 'reminder' }]
      notificationsToSave.scopes[ACCOUNT_JOB_COMPLETED_SHIFT_MODEL] = [{ field_name: 'account_job_id' }]
      if (notificationsVal.birthdate !== 'null' && isAccountAdminOrAbove.value === true) {
        notificationsToSave[USER_MODEL].push({ field_name: 'birthdate', field_value: 'reminder', medium: 'email' })
      }
      if (notificationsVal.completedShift.jobIds.length > 0) {
        const medium = notificationsVal.completedShift.medium
        notificationsVal.completedShift.jobIds.forEach((jobId) => {
          notificationsToSave[ACCOUNT_JOB_COMPLETED_SHIFT_MODEL].push({ field_name: 'account_job_id', field_value: jobId, medium })
        })
      }
      return notificationsToSave
    }

    // Methods
    const save = () => {
      isLoading.value = true
      const notificationsToSave = saveNotificationsParams()
      const apiKeys = isUserCompanyAdmin.value === true ? selectedAccounts.value : [props.apiKey]
      saveNotifications({ params: notificationsToSave, apiKeys })
        .then(() => {
          showNotificationsSaved.value = true
          setTimeout(() => (showNotificationsSaved.value = false), 3000)
        })
        .finally(() => (isLoading.value = false))
    }

    // Computed
    const chatNotificationDisabled = computed(() => notifications.value.chatNotification.medium === 'null')
    const shiftCompletedDisabled = computed(() => notifications.value.completedShift.medium === 'null')
    const allDisabled = computed(() => isUserCompanyAdmin.value === true && selectedAccounts.value.length === 0)
    return {
      isLoading,
      isAccountManagerOrAbove,
      isAccountAdminOrAbove,
      isUserAccountEmployee,
      isUserCompanyAdmin,
      notifications,
      mediumSmsDisabled,
      mediumEmaildisabled,
      showNotificationsSaved,
      chatNotificationDisabled,
      shiftCompletedDisabled,
      selectedAccounts,
      allDisabled,
      isRtl,
      save
    }
  }
}
</script>
