<template>
  <v-col class="flex-grow-1 flex-shrink-0 mb-8 tasks-list" v-if="isLoadingTasks">
    <v-skeleton-loader v-for="i in 3" :key="i" class="single-task"
      type="list-item-three-line, actions">
    </v-skeleton-loader>
  </v-col>
  <v-col class="flex-grow-1 flex-shrink-0 mb-8 tasks-list" v-else>
    <div class="pps-4 t-800-20 color5 text-center" v-if="hasActiveTask">
      <span class="mx-2">{{ activeTaskDescription }}</span>
      <span class="color2 ltr t-700-18 d-inline-block">
        {{ activeTaskTotalTasksDone }} / {{ activeTaskTotalTasks }}
      </span>
    </div>
    <template v-for="part in tasksByShift">
      <div>
        <template v-for="task in part">
          <div v-if="task.header" :key="task.name" class="t-800-20 pps-4 mb-2 color5 header">
            <span v-if="showHeader(task)">
              {{ task.translatedlName }}
            </span>
          </div>
          <single-task-layout v-else-if="!task.header"
            :key="task.id"
            :api-key="apiKey"
            :client-date="clientDateIso"
            :task="task"
            :task-enabled-options="taskOptions"
            :disabled="tasksDisabled"
            hq-view>
          </single-task-layout>
        </template>
      </div>
    </template>
  </v-col>
</template>
<style lang="scss">
.tasks-list {
  overflow-y: auto;

  input[type="file"] {
    display: none;
  }

  .header {
    &+.single-task {
      border-top-left-radius: 12px;
      border-top-right-radius: 12px;
      border-top: 1px solid #DADADA;
    }

    &~.single-task:last-of-type {
      border-bottom-left-radius: 12px;
      border-bottom-right-radius: 12px;
      border-bottom: 1px solid #DADADA;
      margin-bottom: 8px;
    }
  }

  .single-task:not(:last-child) {
    border-bottom: 1px solid #C9C9C9;
  }

  @media only screen and (max-width: 600px) {
    overflow-y: unset;
  }
}
</style>
<script>

import SingleTaskLayout from '@/components/tasks/tasks_list/single_task/SingleTaskLayout.vue'

import useAuth from '@/composables/useAuth.js'
import useCrossAccountDailyTask from '@/composables/useCrossAccountDailyTask.js'

import { isObject } from '@/helpers/Utils.js'

import { i18n } from '@/i18n.js'

import { computed } from 'vue'

const shiftPartToIndex = { undone: 0, done: 1 }

const shiftParts = () =>
  [
    [{ header: true, name: 'undone' }],
    [{ header: true, name: 'done', translatedlName: i18n.t('done') }]
  ]

export default {
  props: {
    apiKey: {
      type: String,
      required: true
    }
  },
  components: {
    'single-task-layout': SingleTaskLayout
  },
  setup(props) {
    const { crossAccountTasks, isLoadingTasks, tasksDisabled, clientDateIso, acitveTask } = useCrossAccountDailyTask(props.apiKey)

    const { isUserAccountEmployee } = useAuth()

    const taskOptions = computed(() => {
      if (isUserAccountEmployee.value) {
        return {
          share: true,
          notification: true
        }
      }

      return {
        edit: true,
        delete: true,
        share: true,
        notification: true,
        copy: true
      }
    })

    // Methods
    const showHeader = (task) => task.header && task.name === 'done'

    // Computed
    const hasActiveTask = computed(() => isObject(acitveTask.value))
    const activeTaskDescription = computed(() => hasActiveTask.value ? acitveTask.value.operation_description : '')
    const activeTaskTotalTasks = computed(() => hasActiveTask.value ? acitveTask.value.total_tasks : '')
    const activeTaskTotalTasksDone = computed(() => hasActiveTask.value ? acitveTask.value?.total_tasks_reported : '')


    const tasksByShift = computed(() => {
      const shiftPartsOrdered = shiftParts()
      const [undone, done] = crossAccountTasks.value.reduce((acc, task) => {
        const isReported = task.isReported()
        const designatedShiftPart = isReported ? 'done' : 'undone'
        const index = shiftPartToIndex[designatedShiftPart]
        acc[index].push(task)
        return acc
      }, shiftPartsOrdered)
      return [undone, done].filter((shiftPart) => shiftPart.length > 1)
    })

    return {
      isLoadingTasks,
      crossAccountTasks,
      tasksByShift,
      clientDateIso,
      taskOptions,
      tasksDisabled,
      hasActiveTask,
      activeTaskDescription,
      activeTaskTotalTasks,
      activeTaskTotalTasksDone,
      showHeader
    }
  }
}
</script>