import { i18n } from '@/i18n.js'
import { isObject, isString, isDate } from '@/helpers/Utils.js'
import { dateToISO8601String, dateToHHMMSSTime, timeStringToUserFormat, isValidISO8601Date, getClientDateFormatted, ISO8601StringToDate, isValidISO8601Time } from '@/helpers/DateTime.js'

import { update as BrokenItemClientUpdate } from '@/api_client/BrokenItem.js'

import { BCP47ToCountryCode, defaultCountryCode } from '@/helpers/TimeZone.js'

import { generalItemId } from '@/composables/useItem.js'
const filedList = ['id', 'item_id', 'status', 'open_date', 'open_time', 'close_date', 'close_time',
  'description', 'progress_description', 'price', 'open_date_user_format', 'close_date_user_format',
  'comment', 'priority', 'chat_messages_count', 'unread_messages_count', 'images', 'fixed_images', 'assigned_users',
  'attached_file_url', 'attached_video_url', 'deadline_date', 'deadline_time', 'tags', 'item_category_id', 'item_category_name',
  'account_api_key', 'account_name', 'district_name', 'related_providers']

const statuses = {
  open: 'open',
  close: 'close',
  in_progress: 'in_progress',
  done: 'done'
}
const priorities = { low: 'low', high: 'high' }
const milliSecondsInDay = 1000 * 60 * 60 * 24

function BrokenItemModel({ src, locale }) {
  const srcOrEmpty = isObject(src) ? src : getDefaultBrokenItem()
  this.update(srcOrEmpty, locale)
}

function setItem(itemData) {
  if (this.item_id === generalItemId.value) {
    this.item_name = i18n.t('devices.general_name')
  } else {
    this.item_name = itemData.name
  }

  this.item_department = isObject(itemData.department) ? itemData.department.name : itemData.department
}

BrokenItemModel.prototype.update = function (updatedBrokenItem, locale) {
  filedList.forEach((field) => {
    this[field] = updatedBrokenItem[field]
  })

  if (updatedBrokenItem.item) setItem.call(this, updatedBrokenItem.item)
  setCreator.call(this, updatedBrokenItem)
  setClosingUser.call(this, updatedBrokenItem)
  this.statusText = translatedStatus(this.status)
  if (validPriority(this.priority)) {
    this.priorityText = translatedPriority(this.priority)
  }
  this.closeDateFormatted = this.close_date_user_format
  this.openDateFormatted = this.open_date_user_format
  if (this.hasOpenTime()) {
    this.openTimeFormatted = timeStringToUserFormat({ timeString: this.open_time, localeCode: locale })
  }
  if (this.hasCloseTime()) {
    this.closeTimeFormatted = timeStringToUserFormat({ timeString: this.close_time, localeCode: locale })
  }
  if (this.hasDeadlineDate()) {
    const countryCode = BCP47ToCountryCode[locale] || defaultCountryCode
    const deadlineDateObj = ISO8601StringToDate(this.deadline_date)
    this.deadlineUserFormatted = getClientDateFormatted({ fromDate: deadlineDateObj, countryCode })
  }

  if (this.hasDeadlineTime()) {
    this.deadlineTimeUserFormatted = timeStringToUserFormat({ timeString: this.deadline_time, localeCode: locale })
  }

  if (!this.isClosed() && isValidISO8601Date(this.open_date)) {
    const openDate = ISO8601StringToDate(this.open_date)
    this.numberDaysOpen = Math.floor((new Date() - openDate) / milliSecondsInDay)
  }
}

function getDefaultBrokenItem() {
  const creationTime = new Date()
  return {
    open_date: dateToISO8601String(creationTime),
    open_time: dateToHHMMSSTime(creationTime),
    close_date: '',
    status: statuses.open,
    priority: priorities.low,
    creator_first_name: null,
    creator_last_name: null,
    images: [],
    fixed_images: [],
    tags: [],
    related_providers: [],
  }
}

function getAllStatuses({ addClosedStatus = false, closeTranslation = null } = {}) {
  const result = [
    { name: statuses.open, translatedName: translatedStatus(statuses.open) },
    { name: statuses.in_progress, translatedName: translatedStatus(statuses.in_progress) },
    { name: statuses.done, translatedName: translatedStatus(statuses.done) }
  ]
  if (addClosedStatus) {
    const translatedName = closeTranslation || translatedStatus(statuses.close)
    result.push({ name: statuses.close, translatedName })
  }
  return result
}

function getAllPriorities() {
  return [
    { name: priorities.low, translatedName: translatedPriority(priorities.low) },
    { name: priorities.high, translatedName: translatedPriority(priorities.high) }
  ]
}

function translatedStatus(status) {
  const translatedNameKey = `broken_item_dialog_dialog.status_${status}`
  return i18n.t(translatedNameKey)
}

function translatedPriority(priority) {
  const translatedNameKey = `broken_item_dialog_dialog.priority_${priority}`
  return i18n.t(translatedNameKey)
}

function validPriority(priority) {
  return priority in priorities
}

function setCreator({ creator }) {
  if (!isObject(creator)) return
  this.creator_first_name = creator.first_name
  this.creator_last_name = creator.last_name
}

function setClosingUser({ closing_user: closingUser = null }) {
  if (!isObject(closingUser)) return
  this.closing_user_first_name = closingUser.first_name
  this.closing_user_last_name = closingUser.last_name
}

BrokenItemModel.prototype.serialize = function () {
  const creationParams = JSON.parse(JSON.stringify(this))
  const result = filedList.reduce((initValue, currentValue) => {
    initValue[currentValue] = creationParams[currentValue]
    return initValue
  }, {})

  const deadlineDate = this.deadline_date
  if (isDate(deadlineDate)) {
    result.deadline_date = dateToISO8601String(deadlineDate)
  }
  if (Array.isArray(this.related_providers)) {
    result.related_provider_ids = this.related_providers.map((provider) => provider.id)
  }

  delete result.related_providers
  delete result.item_id
  delete result.id
  delete result.closeDateFormatted
  delete result.openDateFormatted
  delete result.openTimeFormatted
  delete result.closeTimeFormatted
  delete result.deadlineUserFormatted
  delete result.open_date_user_format
  delete result.close_date_user_format
  delete result.images
  delete result.fixed_images
  delete result.assigned_users
  delete result.closing_user_first_name
  delete result.closing_user_last_name
  delete result.chat_messages_count
  delete result.unread_messages_count
  delete result.attached_file_url
  delete result.attached_video_url
  delete result.item_category_id
  delete result.item_category_name
  delete result.account_api_key
  delete result.account_name
  delete result.district_name
  delete result.numberDaysOpen
  delete result.deadlineTimeUserFormatted
  return result
}

BrokenItemModel.prototype.isClosed = function () {
  return this.status === statuses.close
}

BrokenItemModel.prototype.hasPriority = function () {
  return validPriority(this.priority)
}

BrokenItemModel.prototype.hasCreatorData = function () {
  return isString(this.creator_first_name) && isString(this.creator_last_name)
}

BrokenItemModel.prototype.creatorText = function () {
  return `${this.creator_first_name} ${this.creator_last_name}`
}

BrokenItemModel.prototype.hasClosingUserData = function () {
  return isString(this.closing_user_first_name) && isString(this.closing_user_last_name)
}

BrokenItemModel.prototype.closingUserText = function () {
  return `${this.closing_user_first_name} ${this.closing_user_last_name}`
}

BrokenItemModel.prototype.setClosingTime = function () {
  const closingTime = new Date()
  this.close_date = dateToISO8601String(closingTime)
  this.close_time = dateToHHMMSSTime(closingTime)
}

BrokenItemModel.prototype.hasChatMessages = function () {
  return Number.isInteger(this.chat_messages_count) && this.chat_messages_count > 0
}

BrokenItemModel.prototype.updateMessageCount = function (newMessageCount) {
  this.chat_messages_count = newMessageCount
}

BrokenItemModel.prototype.markAllMessagesAsRead = function () {
  this.unread_messages_count = 0
}

BrokenItemModel.prototype.hasUnreadChatMessages = function () {
  return Number.isInteger(this.unread_messages_count) && this.unread_messages_count > 0
}

BrokenItemModel.prototype.hasOpenTime = function () {
  return isString(this.open_time)
}

BrokenItemModel.prototype.hasNumberOfDaysOpen = function () {
  return Number.isInteger(this.numberDaysOpen)
}

BrokenItemModel.prototype.hasCloseTime = function () {
  return isString(this.close_time)
}

BrokenItemModel.prototype.hasDeadlineDate = function () {
  return isValidISO8601Date(this.deadline_date)
}
BrokenItemModel.prototype.hasDeadlineTime = function () {
  return this.hasDeadlineDate() && isValidISO8601Time(this.deadline_time)
}

BrokenItemModel.prototype.updateStatus = function ({ newStatus, apiKey }) {
  const originalStatus = this.status
  this.status = newStatus
  const updateParams = this.serialize()

  if (originalStatus === statuses.close && newStatus !== statuses.close) {
    updateParams.close_date = ''
  }
  return BrokenItemClientUpdate(apiKey, this.item_id, this.id, { broken_item: updateParams })
    .then((response) => {
      this.update(response.data.data)
      return response.data.data
    })
}

function newBrokenItemModel({ src = null, locale = i18n.locale } = {}) {
  return new BrokenItemModel({ src, locale })
}

const statusColors = {
  open: '#F7B3C2',
  in_progress: '#FFE0B3',
  done: '#B3C6FF',
  close: '#43ff64d9'
}

const statusSecondColors = {
  open: '#FF1744',
  in_progress: '#FF9800',
  done: '#0040FF',
  close: 'green'
}

const deadlineColors = {
  thisWeek: '#00C371',
  today: '#F28240',
  passed: '#EC2328'
}

export {
  priorities, statusColors, statusSecondColors, statuses, deadlineColors,
  newBrokenItemModel, getAllStatuses, getAllPriorities
}
