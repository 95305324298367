<template>
  <div class="user-notifications">
    <v-tabs class="sticky-header elevation-2 u-n-tabs"
      slider-color="black"
      v-model="selectedTab"
      @change="tabChange"
      color="white">
      <v-tab
        href="#jobs">
        {{ isUserCompanyAdmin ? $t('user_notifications.job.tab_title_for_comapny_admins') :
          $t('user_notifications.job.tab_title') }}
      </v-tab>
      <v-tab
        href="#broken_items">
        {{ $t('user_notifications.broken_item.tab_title') }}
      </v-tab>
      <v-tab v-if="isUserCompanyAdmin"
        href="#staff_job">
        {{ $t('user_notifications.staff_job.tab_title') }}
      </v-tab>
      <v-tab
        href="#app_chat">
        {{ $t('user_notifications.app_chat.tab_title') }}
      </v-tab>
      <v-tab
        href="#communnity">
        {{ $t('user_notifications.communnity.tab_title') }}
      </v-tab>
      <v-tab v-if="isAccountAdminOrAbove"
        href="#reports">
        {{ $t('user_notifications.reports.tab_title') }}
      </v-tab>
    </v-tabs>
    <v-tabs-items v-model="selectedTab" touchless class="pt-15" v-bind:class="{ rtl: isRtl }">
      <span class="font-weight-bold red--text px-4">{{ userDisplayName }}</span>
      <v-tab-item value="jobs">
        <job-notifications-tab :api-key="apiKey"></job-notifications-tab>
      </v-tab-item>
      <v-tab-item value="broken_items">
        <broken-item-notifications-tab :api-key="apiKey"></broken-item-notifications-tab>
      </v-tab-item>
      <v-tab-item value="staff_job" v-if="isUserCompanyAdmin">
        <staff-job-notification-tab v-if="apiKeyLoaded" :api-key="apiKey">
        </staff-job-notification-tab>
        <v-progress-circular indeterminate color="primary" v-else></v-progress-circular>
      </v-tab-item>
      <v-tab-item value="app_chat">
        <app-chat-notification-tab :api-key="apiKey"></app-chat-notification-tab>
      </v-tab-item>
      <v-tab-item value="communnity">
        <community-notification-tab :api-key="apiKey"></community-notification-tab>
      </v-tab-item>
      <v-tab-item value="reports" v-if="isAccountAdminOrAbove">
        <reports-tab :api-key="apiKey"></reports-tab>
      </v-tab-item>
    </v-tabs-items>
  </div>

</template>
<style lang="scss">
.user-notifications {
  margin-top: 20px;

  .v-footer.save-notifications {
    bottom: 65px !important;
  }

  .u-n-tabs {
    .v-slide-group__prev {
      display: none !important;
    }
  }

  .sticky-header {
    top: 60px;
    height: unset;
  }

  .v-input--radio-group {
    margin-top: 0;
    padding-top: 0;
  }

  @media only screen and (max-width: 600px) {
    margin-top: 60px;

    .sticky-header {
      top: 112px;
    }

    .v-footer.save-notifications {
      bottom: 56px !important;
    }
  }

}
</style>
<script>

import BrokenItemNotificationsTab from '@/components/user_notifications/BrokenItemNotificationsTab.vue'
import JobNotificationsTab from '@/components/user_notifications/JobNotificationsTab.vue'
import StaffJobNotificationTab from '@/components/user_notifications/StaffJobNotificationTab.vue'
import AppChatNotificationTab from '@/components/user_notifications/AppChatNotificationTab.vue'

import useCompany from '@/composables/useCompany.js'
import useUserNotification from '@/composables/useUserNotification.js'
import useAuth from '@/composables/useAuth.js'

import { isString } from '@/helpers/Utils.js'

import { loadTab, saveTab, unsetTab } from '@/helpers/TabPersist.js'

import { isRtl } from '@/i18n.js'

import { ref, computed } from 'vue'

const defaultTab = 'jobs'
export default {
  components: {
    'broken-item-notifications-tab': BrokenItemNotificationsTab,
    'job-notifications-tab': JobNotificationsTab,
    'staff-job-notification-tab': StaffJobNotificationTab,
    'app-chat-notification-tab': AppChatNotificationTab,
    'community-notification-tab': () => import('@/components/user_notifications/CommunityNotificationTab.vue'),
    'reports-tab': () => import('@/components/user_notifications/ReportsTab.vue')
  },
  beforeRouteLeave(to, from, next) {
    unsetTab()
    next()
  },
  setup() {
    const { apiKey, isAccountAdminOrAbove, isUserCompanyAdmin } = useAuth()
    const selectedTab = ref(loadTab({ defaultTab }))
    const { loadCurrentUser, userDisplayName } = useUserNotification(apiKey.value)
    const { staffAccountApiKey } = useCompany(apiKey.value)
    const apiKeyLoaded = computed(() => isString(staffAccountApiKey.value) === true &&
      staffAccountApiKey.value.length > 0)

    loadCurrentUser()

    // Methods
    const tabChange = (currentTab) => saveTab({ currentTab })
    return {
      apiKey,
      selectedTab,
      userDisplayName,
      isAccountAdminOrAbove,
      isUserCompanyAdmin,
      staffAccountApiKey,
      apiKeyLoaded,
      tabChange,
      isRtl
    }
  }
}
</script>
