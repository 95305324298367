<template>
  <v-container fluid :class="{ 'rtl': isRtl, 'ltr': !isRtl }" class="items-page full-height pa-0">
    <div cols="12" class="top-part-image">
    </div>
    <items-filter :api-key="currentApiKey"></items-filter>
    <items-layout-big-devices v-if="$vuetify.breakpoint.mdAndUp"
      class="bg-color17"
      :api-key="currentApiKey">
    </items-layout-big-devices>
    <items-layout-small-devices v-else
      class="bg-color17"
      :api-key="currentApiKey">
    </items-layout-small-devices>
  </v-container>
</template>
<script>
import ItemsLayoutBigDevices from '@/components/items/ItemsLayoutBigDevices.vue'
import ItemsLayoutSmallDevices from '@/components/items/ItemsLayoutSmallDevices.vue'
import ItemsFilter from '@/components/items/ItemsFilter.vue'

import useItem from '@/composables/useItem.js'
import useAuth from '@/composables/useAuth.js'

import { isRtl } from '@/i18n'
export default {
  components: {
    'items-layout-big-devices': ItemsLayoutBigDevices,
    'items-layout-small-devices': ItemsLayoutSmallDevices,
    'items-filter': ItemsFilter
  },
  setup() {
    const { apiKey, isUserCompanyAdmin } = useAuth()
    const { currentApiKey, initPageData } = useItem(apiKey.value)

    // Methods
    if (isUserCompanyAdmin.value !== true) {
      initPageData(apiKey.value)
    }
    return {
      isRtl,
      currentApiKey,
      isUserCompanyAdmin
    }
  }
}
</script>
