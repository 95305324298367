<template>
  <v-row align="center" justify="start" no-gutters
    class="pa-4 task-bank-single-task">
    <v-col class="row no-gutters align-center justify-start flex-grow-1 flex-shrink-0">
      <v-col class="flex-grow-0 flex-shrink-1">
        <v-avatar color="black" size="40">
          <v-icon color="white">
            {{ taskIcon }}
          </v-icon>
        </v-avatar>
      </v-col>
      <v-col class="flex-grow-1 flex-shrink-0">
        <span v-if="showCreator" class="pps-2 color3 t-500-16">
          {{ $t('task_bank.created_by', {
            firstName: task.creator_data.first_name, lastName: task.creator_data.last_name
          }) }}
        </span>
        <div class="pps-2 color1 t-500-13" v-if="hqContext">
          {{ $t('task_bank.assigned_to_x_jobs', { jobsCount: task.total_tasks }) }}
        </div>
      </v-col>
    </v-col>
    <v-col class="flex-grow-0 flex-shrink-1">
      <task-bank-single-task-options
        :task="task"
        @edit-click="onEditClick"
        @delete-click="onDeleteClick"
        @copy-click="onCopyClick">
      </task-bank-single-task-options>
    </v-col>
    <v-col cols="12" class="divider bg-color18 mt-2">
    </v-col>
    <v-col cols="12" class="color5 t-700-16 mt-1" v-if="showAccountData">
      {{ task.account_name }}
      <span v-if="showDistrict">
        - {{ task.district_name }}
      </span>
    </v-col>
    <v-col cols="12" class="color5 t-500-16 mt-1 word-break">
      {{ task.operation_description }}
    </v-col>
    <v-col cols="12" class="color5 t-500-13 mt-1 color5" v-if="showDepartment">
      <v-icon color="#0000008a">pin_drop</v-icon>
      {{ task.department_name }}
    </v-col>
    <v-col cols="12" class="color5 t-500-13 mt-1 color5" v-if="!hqContext">
      <v-icon color="#0000008A">person_outline</v-icon>
      {{ task.account_job_name }} <span class="color18" v-if="!staffAccount"> | </span>
      <span v-if="!staffAccount">{{ translateShift(task.account_job_shift) }}</span>
    </v-col>
    <v-col v-if="showFrequcnyText" class="mt-1 color7">
      <v-icon>
        schedule
      </v-icon>
      <span class="t-400-12 pps-1">{{ taskFrequencyText }}</span>
    </v-col>
    <v-col cols="12" class="mt-2" v-if="showReportTag || showHqIcon">
      <div class="task-tag bg-color1" v-if="showReportTag">
        <v-icon color="white" class="material-icons-outlined">
          sell
        </v-icon>
        <span>
          {{ translatedReportTag }}
        </span>
      </div>
      <div class="task-tag black mms-2" v-if="showHqIcon">
        <v-icon color="white" class="material-icons-outlined">
          home_work
        </v-icon>
      </div>
    </v-col>
  </v-row>
</template>
<style lang="scss">
.task-bank-single-task {
  border-radius: 16px;
  background-color: white;
  box-shadow: 0px 16px 24px 0px rgba(0, 0, 0, 0.06), 0px 2px 6px 0px rgba(0, 0, 0, 0.04), 0px 0px 1px 0px rgba(0, 0, 0, 0.04);
  height: 100%;

  .divider {
    height: 1px;
  }

  .task-tag {
    padding: 4px 8px;
    border-radius: 8px;
    color: white;
    display: inline-block;
    font-size: 14px;
    font-weight: 500;
  }
}
</style>
<script>
import TaskBankSingleTaskOptions from '@/components/task_bank/TaskBankSingleTaskOptions.vue'
import { translateShift } from '@/store/modules/OrganizationJob.js'
import { translateReportTag } from '@/store/modules/OperationSchedule.js'
import { frequencyTextFromTask, FrequencyTypes } from '@/store/modules/OperationFrequency.js'
import { isObject, isString } from '@/helpers/Utils.js'

import { computed } from 'vue'
export default {
  props: {
    task: {
      type: Object,
      required: true
    },
    staffAccount: {
      type: Boolean,
      default: true
    },
    hqContext: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  components: {
    'task-bank-single-task-options': TaskBankSingleTaskOptions
  },
  setup(props, { emit }) {

    // Methods
    const onEditClick = () => emit('edit-click', props.task)
    const onDeleteClick = () => emit('delete-click', props.task)
    const onCopyClick = () => emit('copy-click', props.task)


    // Computed
    const showAccountData = computed(() => !props.staffAccount && !props.hqContext)
    const showCreator = computed(() => isObject(props.task.creator_data))
    const showDepartment = computed(() => !props.hqContext && isString(props.task.department_name))
    const showDistrict = computed(() => isString(props.task.district_name))
    const showReportTag = computed(() => isString(props.task.report_tag))
    const translatedReportTag = computed(() => showReportTag.value ? translateReportTag(props.task.report_tag) : '')
    const showHqIcon = computed(() => !props.hqContext && props.task.staff_operation)
    const showFrequcnyText = computed(() => props.task.frequency_type !== FrequencyTypes.EveryDay)
    const taskIcon = computed(() => {
      if (Array.isArray(props.task.item_operation_list)) {
        return 'format_list_bulleted'
      }
      if (props.task.require_image) {
        return 'photo_camera'
      }
      return 'check_circle'
    })

    const taskFrequencyText = computed(() => {
      const task = props.task
      const dayName = task.day
      return frequencyTextFromTask({
        task,
        dateString: task.first_scheduled_date,
        dayName
      })
    })

    return {
      taskIcon,
      showCreator,
      showDistrict,
      showReportTag,
      showDepartment,
      translatedReportTag,
      showHqIcon,
      showFrequcnyText,
      taskFrequencyText,
      showAccountData,
      translateShift,
      onEditClick,
      onDeleteClick,
      onCopyClick
    }
  }
}
</script>