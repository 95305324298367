<template>
  <v-select
    :value="value"
    :items="companyDistrictsWithEmpty"
    :label="$t('account_selection.select_area')"
    item-value="id"
    item-text="name"
    :loading="getIsLoading"
    class="district-select"
    @input="onUpdate"
    @change="onChange"
    outlined
    multiple
    :menu-props="menuProps"
    dense clearable>
    <template v-slot:item="{ item: area, attrs }">
      <v-row no-gutters justify="space-between" align="center" :class="{ 'rtl': isRtl, 'ltr': !isRtl }">
        <v-col cols="8" class="font-weight-bold">
          {{ area.name }} ({{ area.accounts_count || 0 }})
        </v-col>
        <v-col cols="4" class="texts-end">
          <v-icon v-if="attrs.inputValue">check_box</v-icon>
          <v-icon v-else>check_box_outline_blank</v-icon>
        </v-col>
      </v-row>
    </template>
  </v-select>
</template>
<style lang="scss">
.district-select {
  fieldset {
    legend {
      display: none;
    }
  }
}
</style>
<script>
import { getCompanyDistricts, loadCompanyDistricts, getIsLoading, getAccountsWithoutDistrictsSize } from '@/models/CompanyDistrict.js'

import { handler } from '@/classes/ErrorHandler.js'

import { i18n, isRtl } from '@/i18n.js'
import { computed } from 'vue'
const EMPTY_AREA_ID = -1
export default {
  props: {
    apiKey: {
      type: String,
      required: true
    },
    value: {
      type: Array,
      default() {
        return []
      }
    },
    menuProps: {
      type: Object,
      required: false,
      default: () => { }
    },
  },
  setup(props, { emit }) {
    const emptyAreaSelectOption = () => {
      const emptyAreaText = i18n.t('account_selection.empty_area')
      return { id: EMPTY_AREA_ID, name: emptyAreaText, accounts_count: getAccountsWithoutDistrictsSize.value }
    }

    // Methods
    const onUpdate = (newValue) => emit('input', newValue)
    const onChange = (newValue) => emit('change', newValue)

    // Computed

    const companyDistrictsWithEmpty = computed(() => {
      const companyDistricts = getCompanyDistricts.value
      if (companyDistricts.length === 0) return companyDistricts
      return [emptyAreaSelectOption()].concat(companyDistricts)
    })

    loadCompanyDistricts({ apiKey: props.apiKey })
      .catch(handler)
    return {
      getIsLoading,
      EMPTY_AREA_ID,
      isRtl,
      companyDistrictsWithEmpty,
      onUpdate,
      onChange
    }
  }
}
</script>