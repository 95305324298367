<template>
  <v-row align="center" justify="start" class="single-push-history pointer"
    :class="{ viewed: pushNotification.viewed, 'pa-3': !dense, 'pa-0': dense }"
    @click="onPushClick">
    <v-col class="flex-grow-0 flex-shrink-1">
      <v-avatar :color="color" size="36">
        <v-icon color="black">
          {{ icon }}
        </v-icon>
      </v-avatar>
    </v-col>
    <v-col class="flex-grow-1 flex-shrink-0">
      <single-notifications-history-text
        :push-notification="pushNotification"
        :dense="dense">
      </single-notifications-history-text>
      <div class="t-400-16 color2">
        {{ timeFromCreation }}
      </div>
    </v-col>
    <v-col cols="2" class="texts-end ppe-0" v-if="!dense">
      <v-btn icon color="#757575" :disabled="pushNotification.isLoading"
        :loading="pushNotification.isLoading"
        @click.stop="onToggleViewed">
        <v-icon>{{ viewedIcon }}</v-icon>
      </v-btn>
      <v-btn icon color="#696969" :disabled="pushNotification.isLoading"
        :loading="pushNotification.isLoading"
        @click.stop="onDeleteClick">
        <v-icon>delete</v-icon>
      </v-btn>
    </v-col>
  </v-row>
</template>
<style lang="scss">
.single-push-history {
  border-radius: 8px;
  border: 1px solid #DADADA;
  background: #FFF;
  padding: 16px;

  &.viewed {
    background: #F1F4FE;
  }
}
</style>
<script>
import SingleNotificationsHistoryText from '@/components/push_notifications_history_page/SingleNotificationsHistoryText.vue'
import { diffBetweenDatesInHoursAndMinutes, translateHoursAndMinutes } from '@/helpers/DateTime.js'


import { isRtl } from '@/i18n'

import { computed } from 'vue'
const modelToIconAndColor = {
  app_chat: {
    icon: 'chat_bubble',
    color: '#00C371'
  },
  blog_post: {
    icon: 'import_contacts',
    color: '#FFA2B4'
  },
  broken_item: {
    icon: 'assignment_late',
    color: '#FF1744'
  },
  operation_schedule: {
    icon: 'assignment_turned_in',
    color: '#FF9800'
  },
  account_job_completed_shift: {
    icon: 'assignment_turned_in',
    color: '#FF9800'
  }
}
export default {
  props: {
    pushNotification: {
      type: Object,
      required: true
    },
    dense: {
      type: Boolean,
      default: false
    }
  },
  components: {
    'single-notifications-history-text': SingleNotificationsHistoryText
  },
  setup(props, { emit }) {
    // Methods
    const onToggleViewed = () => emit('toggle-viewed', props.pushNotification)
    const onDeleteClick = () => emit('delete-click', props.pushNotification)
    const onPushClick = () => emit('push-click', props.pushNotification)

    // Computed
    const viewedIcon = computed(() => props.pushNotification.viewed ? 'drafts' : 'mark_email_unread')
    const pushModel = computed(() => props.pushNotification.push_data.model)
    const icon = computed(() => modelToIconAndColor[pushModel.value]?.icon)
    const color = computed(() => modelToIconAndColor[pushModel.value]?.color)
    const timeFromCreation = computed(() => {
      const creationDate = new Date(props.pushNotification.created_at)
      const now = new Date()
      const { hours, minutes } = diffBetweenDatesInHoursAndMinutes(creationDate, now)
      return translateHoursAndMinutes({ hours, minutes, maxHours: 24 })
    })

    return {
      icon,
      color,
      timeFromCreation,
      isRtl,
      viewedIcon,
      onToggleViewed,
      onDeleteClick,
      onPushClick
    }
  }
}
</script>