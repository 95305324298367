<template>
  <v-container fluid class="px-0 menu-page" :class="{ 'rtl': isRtl, 'ltr': !isRtl }">
    <company-settings ref="companySettings" v-if="isUserCompanyAdmin"></company-settings>
    <general-settings :api-key="apiKey" ref="generalSettings"
      v-if="companyContext || showCompanySettingsForAdmin"></general-settings>
    <account-edit-dialog
      :api-key="apiKey"
      show-account-select
      ref="accountEditDialog"
      v-if="isUserCompanyAdmin">
    </account-edit-dialog>
    <account-settings ref="accountSettings" v-if="isUserAccountAdmin"></account-settings>
    <v-row align="start" justify="start"
      :class="{ 'pps-15': $vuetify.breakpoint.mdAndUp }">
      <v-col cols="12" sm="6" md="5">
        <v-row align="center" justify="start">
          <v-col cols="12" class="text-center">
            <user-avatar></user-avatar>
          </v-col>
          <v-col cols="12" class="mt-4" v-if="showMenuTitles">
            <span class="menu-title"> {{ $t('menu_page.title_general') }}</span>
          </v-col>
          <v-col cols="12" class="mb-4">
            <menu-item color="#355C7D" icon="person_outline" :text="$t('menu_page.profile')" :to="userProfileRoute">
            </menu-item>
          </v-col>
          <v-col cols="12" class="mb-4" v-if="isUserAccountEmployee">
            <menu-item color="#43357D" icon="people_alt" :text="$t('menu_page.team')"
              :sub-text="$t('menu_page.team_subtitle_employee')" to="work_panel/employees">
            </menu-item>
          </v-col>
          <v-col cols="12" class="mb-4">
            <menu-item color="#A1B3E8" icon="notifications_active" :text="$t('menu_page.push_history')"
              to="push_history">
            </menu-item>
          </v-col>
          <v-col cols="12" class="mb-4">
            <menu-item color="#439775" icon="cleaning_services" :text="$t('menu_page.service_providers')"
              :sub-text="$t('menu_page.service_providers_subtitle')"
              to="company_service_providers">
            </menu-item>
          </v-col>
          <v-col cols="12" v-if="showMenuTitles">
            <span class="menu-title">{{ $t('menu_page.title_preferences') }}</span>
          </v-col>
          <v-col cols="12" class="mb-4" v-if="showLanguageOrNotifications">
            <menu-item color="#6B5A7A" icon="language" :text="$t('languages.language')" :to="languageSelectionRoute">
            </menu-item>
          </v-col>
          <v-col cols="12" class="mb-4" v-if="showLanguageOrNotifications">
            <menu-item color="#C56A7E" icon="notifications_none" :text="$t('notifications')"
              :to="userNotificationsRoute">
            </menu-item>
          </v-col>
          <v-col cols="12" v-if="showMenuInfo">
            <menu-item color="#6D4C41" icon="help"
              :text="$t('menu_page.help')" :icon-badge="getHaveUnreadMessages"
              :to="menuInfoRoute">
            </menu-item>
          </v-col>
          <v-col cols="12" v-if="showAdminMenu" class="px-0 py-0 align-start justify-start">
            <v-col cols="12" class="mt-4">
              <span class="menu-title">{{ $t('menu_page.title_admin') }}</span>
            </v-col>
            <v-col cols="12" class="mb-4" v-if="isAccountAdminOrAbove">
              <menu-item color="#A86AC5" icon="account_circle"
                :text="$t('account_edit_dialog.title')"
                v-on:click.native="onAccountSettingsClick">
              </menu-item>
            </v-col>
            <v-col cols="12" class="mb-4" v-if="isAccountAdminOrAbove">
              <menu-item color="#C5C16A" icon="domain" :text="$t('departments')"
                to="account_departments">
              </menu-item>
            </v-col>
            <v-col cols="12" class="mb-4">
              <menu-item color="#6C6AC5" icon="work" :text="$t('menu_page.jobs')"
                :sub-text="$t('menu_page.jobs_subtitle')" to="jobs">
              </menu-item>
            </v-col>
            <v-col cols="12" class="mb-4">
              <menu-item color="#43357D" icon="people_alt" :text="$t('menu_page.team')"
                :sub-text="$t('menu_page.team_subtitle')" to="work_panel/employees">
              </menu-item>
            </v-col>
            <v-col cols="12" class="mb-4" v-if="isAccountAdminOrAbove">
              <menu-item color="#FC4A4A" icon="assignment_late"
                :text="$t('menu_page.faults_settings')" to="faults_settings">
              </menu-item>
            </v-col>
            <v-col cols="12" class="mb-4" v-if="isUserAccountAdmin || isUserAccountManager">
              <menu-item color="#C92EB9" icon="account_balance" :text="$t('menu_page.task_bank')" to="task_bank">
              </menu-item>
            </v-col>
            <v-col cols="12" class="mb-4">
              <menu-item color="#8E24AA" icon="work_history"
                :text="$t('menu_page.operation_history')" to="operation_history">
              </menu-item>
            </v-col>
            <v-col cols="12" class="mb-4" v-if="showCompanySettingsForAdmin">
              <menu-item color="#90CAF9" icon="manage_accounts" :text="$t('menu_page.general_settings')"
                v-on:click.native="onGeneralSettingsClick">
              </menu-item>
            </v-col>
          </v-col>
          <v-col cols="12" v-if="companyContext" class="px-0 pt-0 align-start justify-start">
            <v-col cols="12">
              <span class="menu-title">{{ $t('menu_page.title_hq_admin') }}</span>
            </v-col>
            <v-col cols="12" class="mb-4">
              <menu-item color="#7D3535" icon="people" :text="$t('menu_page.company_details')"
                v-on:click.native="opCompanySettingsClick">
              </menu-item>
            </v-col>
            <v-col cols="12" class="mb-4">
              <menu-item color="#7A6F5A" icon="description" :text="$t('menu_page.hq_jobs')" to="staff_account/jobs">
              </menu-item>
            </v-col>
            <v-col cols="12" class="mb-4">
              <menu-item color="#6AC589" icon="people_alt" :text="$t('menu_page.hq_members')"
                :sub-text="$t('menu_page.hq_members_subtitle')" to="staff/work_panel/employees">
              </menu-item>
            </v-col>
            <v-col cols="12" class="mb-4">
              <menu-item color="#90CAF9" icon="manage_accounts" :text="$t('menu_page.general_settings')"
                v-on:click.native="onGeneralSettingsClick">
              </menu-item>
            </v-col>
            <v-col cols="12" class="mb-4">
              <menu-item color="#C92EB9" icon="account_balance" :text="$t('menu_page.task_bank')" to="task_bank">
              </menu-item>
            </v-col>
            <v-col cols="12" class="mb-4">
              <menu-item color="#8E24AA" icon="work_history" :text="$t('menu_page.operation_history')"
                to="staff/operation_history">
              </menu-item>
            </v-col>
          </v-col>
          <v-col cols="12">
            <v-btn icon @click="logout" class="mms-8">
              <v-icon class="logout-icon">logout</v-icon>
              {{ $t('menu_page.logout') }}
            </v-btn>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>
<style lang="scss">
.menu-page {
  padding-top: 110px;
  background-color: #E5E5E5;
  height: 100%;
  padding-bottom: 62px;

  .menu-title {
    font-size: 14px;
    font-weight: 500;
  }

}

.rtl {
  .logout-icon {
    rotate: 180deg;
  }
}
</style>
<script>

import MenuItem from '@/components/menu/MenuItem.vue'
import AccountEditDialog from '@/components/company_stores/AccountEditDialog.vue'
import AccountSettings from '@/components/AccountSettings.vue'
import CompanySettings from '@/components/CompanySettings.vue'
import GeneralSettings from '@/components/GeneralSettings.vue'

import useCompany from '@/composables/useCompany.js'
import useSystemGuidanceTrack from '@/composables/useSystemGuidanceTrack.js'
import useAuth from '@/composables/useAuth.js'

import { isRtl } from '@/i18n'

import { computed, ref } from 'vue'

import { useRoute } from 'vue-router/composables'

export default {
  components: {
    'menu-item': MenuItem,
    'company-settings': CompanySettings,
    'account-edit-dialog': AccountEditDialog,
    'account-settings': AccountSettings,
    'general-settings': GeneralSettings,
    'user-avatar': () => import('@/components/shared/UserAvatar.vue')
  },
  setup() {
    const companySettings = ref(null)
    const generalSettings = ref(null)
    const accountEditDialog = ref(null)
    const accountSettings = ref(null)
    const router = useRoute()

    const { logoutUser, apiKey, isUserCompanyAdmin, isUserAccountAdmin, isUserAccountManager,
      isAccountAdminOrAbove, isAccountManagerOrAbove, isUserAccountEmployee } = useAuth()
    const { getHaveUnreadMessages } = useSystemGuidanceTrack(apiKey.value)
    const { isMultipleAccounts } = useCompany(apiKey.value)
    // Methods

    const logout = () => logoutUser()
    const opCompanySettingsClick = () => companySettings.value.openDialog()
    const onGeneralSettingsClick = () => generalSettings.value.openDialog()
    const onAccountSettingsClick = () => {
      if (isUserCompanyAdmin.value === true) {
        accountEditDialog.value.openDialogWithAccountLoading(apiKey.value)
      } else {
        accountSettings.value.openDialog()
      }
    }

    // Computed
    const showMenuTitles = computed(() => isAccountManagerOrAbove.value)

    const companyContext = computed(() => isUserCompanyAdmin.value === true && router.meta.staffOnly)
    const userProfileRoute = computed(() => companyContext.value === true ? 'staff/user_profile' : 'user_profile')
    const languageSelectionRoute = computed(() => companyContext.value === true ? 'staff/language_selection' : 'language_selection')
    const userNotificationsRoute = computed(() => companyContext.value === true ? 'staff/user_notifications' : 'user_notifications')
    const menuInfoRoute = computed(() => companyContext.value === true ? 'staff/help' : 'help')
    const showAdminMenu = computed(() => isAccountManagerOrAbove.value)
    const showCompanySettingsForAdmin = computed(() => isUserAccountAdmin.value && !isMultipleAccounts.value)

    const showMenuInfo = computed(() => {
      if (isUserCompanyAdmin.value === true) {
        return companyContext.value === true
      }

      return true
    })

    const showLanguageOrNotifications = computed(() => {
      if (isUserCompanyAdmin.value === true && !router.meta.staffOnly) return false

      return true
    })

    return {
      apiKey,
      showMenuTitles,
      userProfileRoute,
      languageSelectionRoute,
      userNotificationsRoute,
      isAccountManagerOrAbove,
      isAccountAdminOrAbove,
      isUserAccountAdmin,
      isUserCompanyAdmin,
      isUserAccountEmployee,
      isUserAccountManager,
      showAdminMenu,
      showCompanySettingsForAdmin,
      companyContext,
      showLanguageOrNotifications,
      companySettings,
      generalSettings,
      accountEditDialog,
      accountSettings,
      menuInfoRoute,
      showMenuInfo,
      getHaveUnreadMessages,
      multiple: false,
      isRtl,
      logout,
      onAccountSettingsClick,
      opCompanySettingsClick,
      onGeneralSettingsClick
    }
  }
}
</script>
