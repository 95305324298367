
import { translateRole, isAccountEmployee, isAccountManager, isCompanyAdmin } from '@/store/modules/EmployeeRole.js'

const filedList = ['id', 'first_name', 'last_name', 'email', 'phone',
  'invitation_status', 'birthdate', 'attached_file_url']

function EmployeeModel(src) {
  const srcOrEmpty = src || {}
  filedList.forEach((field) => {
    this[field] = srcOrEmpty[field]
  })
  if (srcOrEmpty.role) {
    this.role = srcOrEmpty.role
    this.roleText = translateRole(srcOrEmpty.role)
  }
  this.user_allowed_jobs = srcOrEmpty.user_allowed_jobs || []
  this.account_department_ids = srcOrEmpty.account_department_ids || (srcOrEmpty.account_departments || []).map((department) => department.id)
  this.displayName = `${this.first_name} ${this.last_name}`
}

function isEmpty(field) {
  return (this[field] || '').trim().length === 0
}

EmployeeModel.prototype.valid = function () {
  if (isEmpty.call(this, 'first_name') ||
    isEmpty.call(this, 'last_name') ||
    isEmpty.call(this, 'role')) return false
  if (isEmpty.call(this, 'email') && isEmpty.call(this, 'phone')) return false
  return true
}

EmployeeModel.prototype.invitationAccepted = function () {
  return this.invitation_status === 'accepted'
}

EmployeeModel.prototype.invitationPending = function () {
  return this.invitation_status === 'pending'
}

EmployeeModel.prototype.invitationNotInvited = function () {
  return this.invitation_status === 'not_invited'
}

EmployeeModel.prototype.isAccountEmployee = function () {
  return isAccountEmployee(this.role)
}

EmployeeModel.prototype.isAccountManager = function () {
  return isAccountManager(this.role)
}

EmployeeModel.prototype.isCompanyAdmin = function () {
  return isCompanyAdmin(this.role)
}

EmployeeModel.prototype.hasPhoneNumber = function () {
  return isEmpty.call(this, 'phone') === false && this.phone.length > 5
}

EmployeeModel.prototype.serialize = function () {
  const creationParams = JSON.parse(JSON.stringify(this))
  const result = filedList.reduce((initValue, currentValue) => {
    initValue[currentValue] = creationParams[currentValue]
    return initValue
  }, {})
  delete result.invitation_status
  delete result.attached_file
  return result
}

function newEmployeeModel(src) {
  return new EmployeeModel(src)
}

export {
  newEmployeeModel
}
