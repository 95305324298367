<template>
  <v-container fluid class="account-dep" :class="{ 'rtl': isRtl, 'ltr': !isRtl }">
    <account-department-create-dialog ref="createDepartmentDialog" :api-key="apiKey"></account-department-create-dialog>
    <confirm-delete ref="deleteDialog"></confirm-delete>
    <v-btn fab small color="black white--text" class="add-button"
      @click="createNewDepartment">
      <v-icon>add</v-icon>
    </v-btn>
    <v-row align="start" justify="center">
      <v-col cols="12" class="page-title">
        {{ $t("account_department_page.page_title") }}
      </v-col>
      <v-col cols="12" sm="10" lg="8">
        <v-row align="start" justify="space-between" no-gutters>
          <v-col cols="12" md="4" sm="6" v-if="isUserCompanyAdmin" class="mt-4">
            <page-account-select></page-account-select>
          </v-col>
          <v-col cols="12" class="mt-8 mt-sm-2 ml-2">
            <span class="font-weight-bold mx-1">
              {{ $t("account_department_page.total") }}
            </span>
            <span class="font-weight-bold blue--text">
              {{ totalDepartments }}
            </span>
          </v-col>
          <v-col cols="12" class="mt-2">
            <v-data-table :items="allDepartments" :loading="isLoading" class="elevation-1" :headers="tableHeaders"
              :hide-default-header="$vuetify.breakpoint.xsOnly" :loading-text="$t('loading')"
              :items-per-page="DEFAULT_PER_PAGE" hide-default-footer item-key="id">
              <template v-slot:body="{ items }">
                <draggable
                  :list="items"
                  tag="tbody"
                  handle=".drag-handle"
                  @change="onListOrderChanged">
                  <account-department-table-row v-for="item in items"
                    :key="item.id"
                    :department="item" @update-department="updateDepartment"
                    @delete-department="onDeleteDepartment">
                  </account-department-table-row>
                </draggable>
              </template>
              <template v-slot:no-data>
                <v-alert :value="true" color="white black--text">
                  {{ $t("no_result") }}
                </v-alert>
              </template>
            </v-data-table>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>
<style lang="scss">
.account-dep {
  margin-top: 100px;

  .no-image {
    opacity: 0.2;
  }

  @media only screen and (max-width: 600px) {
    margin-top: 140px;
  }
}
</style>
<script>
import AccountDepartmentCreateDialog from '@/components/account_departments/AccountDepartmentCreateDialog.vue'
import AccountDepartmentTableRow from '@/components/account_departments/AccountDepartmentTableRow.vue'

import PageAccountSelect from '@/components/shared/PageAccountSelect.vue'
import draggable from 'vuedraggable'

import ConfirmDelete from '@/components/shared/ConfirmDelete.vue'
import AccountDepartmentStore from '@/components/shared/account_department/AccountDepartmentStore.js'
import useAuth from '@/composables/useAuth.js'

import { handler as errHandler } from '@/classes/ErrorHandler.js'

import { i18n, isRtl } from '@/i18n.js'

import { ref, computed } from 'vue'
const DEFAULT_PER_PAGE = 100
export default {
  components: {
    'account-department-create-dialog': AccountDepartmentCreateDialog,
    'account-department-table-row': AccountDepartmentTableRow,
    'confirm-delete': ConfirmDelete,
    'page-account-select': PageAccountSelect,
    draggable
  },
  setup() {
    const { apiKey, isUserCompanyAdmin } = useAuth()
    const createDepartmentDialog = ref(null)
    const deleteDialog = ref(null)
    const tableHeaders = [
      { align: 'center', text: i18n.t('account_department_page.name_col'), width: '100%', value: 'name', sortable: false }
    ]
    const { loadAllDeparmtnets, deleteDepartment, updateOrder, allDepartments, isLoading } = AccountDepartmentStore(apiKey.value)

    // Methods
    const onListOrderChanged = (event) => {
      const newIndex = event.moved?.newIndex
      const oldIndex = event.moved?.oldIndex
      if (!Number.isInteger(newIndex) || !Number.isInteger(oldIndex) || newIndex === oldIndex) return

      changeDepartmentIndex({ oldIndex, newIndex })
      const newOrder = allDepartments.value.map((department) => department.id)
      updateOrder(newOrder)
    }

    const changeDepartmentIndex = ({ oldIndex, newIndex }) => {
      const item = allDepartments.value.splice(oldIndex, 1)[0]
      allDepartments.value.splice(newIndex, 0, item)
    }

    const createNewDepartment = () => {
      createDepartmentDialog.value.openDialog()
        .then(({ isCreated }) => {
          if (isCreated !== true) return

          loadAllDeparmtnets(true)
        })
    }

    const updateDepartment = (department) => {
      createDepartmentDialog.value.openForUpdate(department)
        .then(({ isCreated } = {}) => {
          if (isCreated !== true) return

          loadAllDeparmtnets(true)
        })
    }

    const onDeleteDepartment = (department) => {
      deleteDialog.value.open()
        .then((shouldDelete) => {
          if (!shouldDelete) return
          deleteDepartment(department.id)
            .then(() => deleteDialog.value.close())
            .catch(errHandler)
        })
    }

    // Computed
    const totalDepartments = computed(() => allDepartments.value.length)

    loadAllDeparmtnets()
    return {
      DEFAULT_PER_PAGE,
      isLoading,
      apiKey,
      allDepartments,
      totalDepartments,
      tableHeaders,
      isUserCompanyAdmin,
      createDepartmentDialog,
      deleteDialog,
      isRtl,
      onListOrderChanged,
      updateDepartment,
      onDeleteDepartment,
      createNewDepartment
    }
  }
}
</script>
