<template>
  <v-dialog v-model="dialog" max-width="360" persistent>
    <create-company-district-dialog
      ref="createDistrictDialog">
    </create-company-district-dialog>
    <operation-image-overlay
      v-bind:showImageOverlay="accountImage.showImageOverlay"
      v-bind:imageSrc="accountImage.url"
      v-on:cancel-report="removeImage"
      v-on:hide-image="hideImageOverlay">
    </operation-image-overlay>
    <v-card class="edit-account-dialog" :class="{ 'rtl': isRtl, 'ltr': !isRtl }">
      <v-card-title class="text-center pb-5 blue-grey lighten-5">
        <span class="headline full-width">
          {{ $t('account_edit_dialog.title') }}
        </span>
      </v-card-title>
      <v-card-text class="pa-2">
        <v-row align="center" justify="center" v-if="isAccountLoading" class="py-4">
          <v-progress-circular indeterminate color="primary"></v-progress-circular>
        </v-row>
        <v-form ref="accountEditForm" v-else>
          <v-container>
            <v-row align="center" justify="start" no-gutters>
              <v-col cols="12" v-if="showAccountSelect">
                <page-account-select
                  :reload-on-change="false"
                  @change="onAccountChange"></page-account-select>
              </v-col>
              <v-col cols="9">
                <district-select
                  v-model="account.districtId"
                  :api-key="apiKey"
                  :show-delete="showDeleteDistrictButton"
                  @district-deleted="onDistrictDelete">
                </district-select>
              </v-col>
              <v-col cols="3" class="texts-end">
                <v-btn fab small dark @click="createDistrict" color="indigo">
                  <v-icon>add</v-icon>
                </v-btn>
              </v-col>
              <v-col cols="9">
                <v-text-field
                  v-model="account.name"
                  single-line
                  :rules="[requiredField]"
                  counter="120"
                  :label="$t('account_name')">
                </v-text-field>
              </v-col>
              <v-col cols="9" class="mt-5">
                <v-img v-if="showImagePreview" max-height="150"
                  @click="previewImageClick"
                  @load="onImageLoaded"
                  @error="onImageLoadError"
                  :src="accountImage.url">
                  <template v-slot:placeholder>
                    <v-row align="center" justify="center" class="fill-height" no-gutters>
                      <v-progress-circular indeterminate color="primary"></v-progress-circular>
                    </v-row>
                  </template>
                </v-img>
                <label for="store_custom_image" class="pointer" v-else>
                  <v-icon class="font-weight-black" color="black"> add_a_photo </v-icon>
                  <input type="file" id="store_custom_image" accept="image/*" :disabled="accountImage.isLoading"
                    @change="uploadImagePreview">
                </label>
              </v-col>
              <v-col cols="12" class="mme-4 mt-5">
                <v-card-actions class="pt-2 ppe-2">
                  <v-spacer></v-spacer>
                  <v-btn color="blue darken-1" text @click="close" :disabled="isLoading">
                    {{ $t('cancel') }}
                  </v-btn>
                  <v-btn color="blue darken-1" depressed tile @click="save" :loading="isLoading"
                    :disabled="isSaveDisabled">
                    {{ $t('save') }}
                  </v-btn>
                </v-card-actions>
              </v-col>
            </v-row>
          </v-container>
        </v-form>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>
<style lang="scss">
.edit-account-dialog {
  input[type="file"] {
    display: none;
  }
}
</style>
<script>

import CreateCompanyDistrictDialog from '@/components/shared/CreateCompanyDistrictDialog.vue'
import OperationImageOverlay from '@/components/shared/OperationImageOverlay.vue'
import PageAccountSelect from '@/components/shared/PageAccountSelect.vue'

import { loadAllAccounts } from '@/components/company_stores/AccountSelectionStore.js'

import {
  show as AccountClientShow,
  update as AccountClientUpdate,
  addStoreImage as AccountClientAddStoreImage,
  removeStoreImage as AccountClientRemoveStoreImage
} from '@/api_client/AccountClient.js'
import { onDialogOpen, onDialogClose } from '@/classes/DialogScrollBug.js'

import useAuth from '@/composables/useAuth.js'
import { requiredField } from '@/classes/InputValidators.js'

import { isString, isFile } from '@/helpers/Utils.js'

import { uploadImage as uploadImageToRemoteServer } from '@/general_client/CloudinaryImageUploader.js'

import { handler as errHandler } from '@/classes/ErrorHandler.js'

import { isRtl } from '@/i18n'

import { ref, computed } from 'vue'
function emptyAccount() {
  return { name: null, districtName: null, districtId: null, customStoreImage: null }
}



function defaultAccountImage() {
  return {
    isLoading: false,
    url: '',
    hasImage: false,
    showImageOverlay: false,
    hadImage: false
  }
}


export default {
  components: {
    'create-company-district-dialog': CreateCompanyDistrictDialog,
    'operation-image-overlay': OperationImageOverlay,
    'page-account-select': PageAccountSelect,
    'district-select': () => import('@/components/company_stores/DistrictSelect.vue')
  },
  props: {
    apiKey: {
      type: String,
      required: true
    },
    showAccountSelect: {
      type: Boolean,
      default: false
    }
  },
  setup(props) {
    const { isUserCompanyAdmin } = useAuth()
    const dialog = ref(false)
    const isLoading = ref(false)
    const isAccountLoading = ref(false)
    const account = ref(emptyAccount())
    const accountName = ref(null)
    const distictName = ref(null)
    const accountImage = ref(defaultAccountImage())
    const createDistrictDialog = ref(null)
    const accountEditForm = ref(null)

    let oldDistrictId = null
    let oldName = null
    let originalUploadedFile = null
    let accountApiKey = null
    let uploadedImagePreviewUrl = null
    let resolveFunc = null

    const isAccountNameValid = () => {
      const accountName = account.value.name
      if (!isString(accountName)) return false

      const length = accountName.trim().length
      return length > 0 && length <= 120
    }

    const releaseImageMemory = () => {
      if (!uploadedImagePreviewUrl) return
      URL.revokeObjectURL(uploadedImagePreviewUrl)
      uploadedImagePreviewUrl = null
    }

    const updaloadImage = async (imageUploadData) => {
      await uploadImageToRemoteServer(originalUploadedFile, imageUploadData)
      return AccountClientAddStoreImage(accountApiKey, imageUploadData.public_id)
    }
    const shouldAddImage = () =>
      accountImage.value.hasImage && isFile(originalUploadedFile)

    const shouldRemoveImage = () =>
      !accountImage.value.hasImage && accountImage.value.hadImage === true

    const removeStoreImage = () => AccountClientRemoveStoreImage(accountApiKey)

    const accountUpdateParams = () => {
      const requestParams = {}
      if (oldName !== account.value.name) {
        requestParams.name = account.value.name
      }
      if (oldDistrictId !== account.value.districtId) {
        requestParams.company_district_id = account.value.districtId
        if (!Number.isInteger(requestParams.company_district_id)) {
          requestParams.company_district_id = null
        }
      }
      if (shouldAddImage()) {
        requestParams.with_image = true
      }
      return requestParams
    }

    const saveAccountChanges = async () => {
      const updateParams = accountUpdateParams()
      const closeResult = { isUpdupdated: false }
      const shouldRemoveImageFromAccount = shouldRemoveImage()
      const updateParamsEmpty = Object.keys(updateParams).length === 0
      if (updateParamsEmpty === true && shouldRemoveImageFromAccount !== true) {
        return closeResult
      }

      isLoading.value = true
      closeResult.isUpdupdated = true
      if (!updateParamsEmpty) {
        const districtUpdated = 'company_district_id' in updateParams
        closeResult.districtUpdated = districtUpdated
        const response = await AccountClientUpdate(accountApiKey, updateParams)
        const responseData = response.data.data
        if (shouldAddImage()) {
          const imageUploadData = responseData.image_upload_data
          await updaloadImage(imageUploadData)
        }
      } else if (shouldRemoveImageFromAccount) {
        await removeStoreImage()
      }
      return closeResult
    }

    const setAccount = (accountVal) => {
      accountImage.value = defaultAccountImage()
      accountApiKey = accountVal.api_key
      account.value.name = accountVal.name
      account.value.districtName = accountVal.district_name
      account.value.districtId = accountVal.company_district_id
      account.value.customStoreImage = accountVal.custom_store_image
      oldName = accountVal.name
      oldDistrictId = accountVal.company_district_id
      if (isString(account.value.customStoreImage) && account.value.customStoreImage.length > 0) {
        accountImage.value.hadImage = true
        accountImage.value.hasImage = true
        accountImage.value.url = account.value.customStoreImage
      }
    }

    // Methods
    const onAccountChange = (selectedAccount) => {
      setAccount(JSON.parse(JSON.stringify(selectedAccount)))
    }
    const onDistrictDelete = () => loadAllAccounts(props.apiKey, true)

    const save = () => {
      saveAccountChanges()
        .then((closeResult) => close(closeResult))
        .catch(errHandler)
        .finally(() => (isLoading.value = false))
    }

    const openDialog = (accountVal) => {
      onDialogOpen()
      dialog.value = true
      setAccount(accountVal)
      return new Promise((resolve, reject) => {
        resolveFunc = resolve
      })
    }

    const openDialogWithAccountLoading = (apiKey) => {
      isAccountLoading.value = true
      AccountClientShow(apiKey)
        .then((response) => {
          const result = openDialog(response.data.data)
          isAccountLoading.value = false
          return result
        })
        .catch((err) => {
          isAccountLoading.value = false
          errHandler(err)
        })
    }
    const createDistrict = () => createDistrictDialog.value.openDialog()

    const uploadImagePreview = (event) => {
      const file = event.target.files[0]
      if (!file) return

      releaseImageMemory()
      accountImage.value.isLoading = true
      uploadedImagePreviewUrl = URL.createObjectURL(file)
      originalUploadedFile = file
      accountImage.value.url = uploadedImagePreviewUrl
    }
    const onImageLoaded = () => {
      accountImage.value.isLoading = false
      accountImage.value.hasImage = true
    }
    const onImageLoadError = () => {
      accountImage.value.isLoading = false
      accountImage.value.hasImage = false
    }
    const previewImageClick = () => {
      if (accountImage.value.hasImage !== true) return

      accountImage.value.showImageOverlay = true
    }

    const hideImageOverlay = () => accountImage.value.showImageOverlay = false
    const removeImage = () => {
      releaseImageMemory()
      hideImageOverlay()
      accountImage.value.url = ''
      accountImage.value.hasImage = false
      accountImage.value.isLoading = false
      originalUploadedFile = null
    }

    const close = ({ isUpdupdated = false, districtUpdated = false } = {}) => {
      onDialogClose()
      dialog.value = false
      isLoading.value = false
      account.value = emptyAccount()
      oldName = null
      oldDistrictId = null
      accountApiKey = null
      accountImage.value = defaultAccountImage()
      releaseImageMemory()
      originalUploadedFile = null
      accountEditForm.value.reset()
      resolveFunc({ isUpdupdated, districtUpdated })
    }

    // Computed
    const showImagePreview = computed(() => accountImage.value.isLoading || accountImage.value.hasImage)
    const isSaveDisabled = computed(() => {
      if (isLoading.value === true) return true
      if (!isAccountNameValid()) return true
      return false
    })

    return {
      showDeleteDistrictButton: isUserCompanyAdmin,
      dialog,
      isRtl,
      isLoading,
      isAccountLoading,
      account,
      accountName,
      distictName,
      accountImage,
      showImagePreview,
      isSaveDisabled,
      accountEditForm,
      createDistrictDialog,
      onAccountChange,
      onDistrictDelete,
      requiredField,
      openDialogWithAccountLoading,
      openDialog,
      createDistrict,
      uploadImagePreview,
      onImageLoaded,
      onImageLoadError,
      previewImageClick,
      hideImageOverlay,
      removeImage,
      close,
      save
    }
  }
}
</script>
