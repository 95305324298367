import { i18n } from '@/i18n.js'
const ROLES = ['company_admin', 'account_admin', 'account_manager', 'account_employee']
const accountAdminAndBelowRoles = ['account_admin', 'account_manager', 'account_employee']
const accountManagerAndBelowRoles = ['account_manager', 'account_employee']
const accountAdminAndAboveRoles = ['account_admin', 'company_admin']

function accountAdminAndBelow () {
  return ROLES.map(singleRoleObject).filter((role) => {
    return accountAdminAndBelowRoles.includes(role.name)
  })
}

function accountManagerAndBelow () {
  return ROLES.map(singleRoleObject).filter((role) => {
    return accountManagerAndBelowRoles.includes(role.name)
  })
}

function isAccountAdminAndAbove (role) {
  return accountAdminAndAboveRoles.includes(role)
}

function isAccountEmployee (role) {
  return role === 'account_employee'
}

function isAccountManager (role) {
  return role === 'account_manager'
}

function isCompanyAdmin (role) {
  return role === 'company_admin'
}

function singleRoleObject (role) {
  return {
    name: role,
    translatedName: translateRole(role)
  }
}

function translateRole (role) {
  const employeeRoleTextKey = `employees_roles.${role}`
  return i18n.t(employeeRoleTextKey)
}
export {
  accountAdminAndBelow,
  isAccountEmployee,
  isAccountManager,
  isCompanyAdmin,
  translateRole,
  isAccountAdminAndAbove,
  accountManagerAndBelow
}
