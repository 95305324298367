import { i18n } from '@/i18n.js'
import { isString } from '@/helpers/Utils.js'

const HOURS_IN_MINUTES = 60
const AVG_DAYS_IN_MONTH = 30.44
const HOURS_IN_DAY = 24
const MONTHS_IN_YEAR = 12
const DAYS = ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday']
const iso8601TimePattern = /^([01]\d|2[0-3]):([0-5]\d)(?::([0-5]\d))?$/
function getClientDate(fromDate = new Date()) {
  const { dd, mm, yyyy } = paddedDate(fromDate)
  return `${dd}-${mm}-${yyyy}`
}

function clientDateToDate(clientDate) {
  const [dd, mm, yyyy] = clientDate.split('-')
  return new Date(yyyy, mm - 1, dd)
}

function getClientDateFormatted({ fromDate = new Date(), countryCode = 'us' } = {}) {
  const lowerCaseCountryCode = countryCode.toLowerCase()
  const { dd, mm, yyyy } = paddedDate(fromDate)
  if (lowerCaseCountryCode === 'il' || lowerCaseCountryCode === 'uk') return `${dd}/${mm}/${yyyy.toString().slice(-2)}`
  if (lowerCaseCountryCode === 'us') return `${mm}/${dd}/${yyyy}`
  if (lowerCaseCountryCode === 'ua') return `${dd}.${mm}.${yyyy}`
  return `${mm}/${dd}/${yyyy}`
}

function paddedDate(date = new Date()) {
  const dd = String(date.getDate()).padStart(2, '0')
  const mm = String(date.getMonth() + 1).padStart(2, '0')
  const yyyy = date.getFullYear()
  return { yyyy, mm, dd }
}

function fromMinutesToHoursAndMinutes(minutes) {
  const hours = Math.floor(minutes / HOURS_IN_MINUTES)
  if (hours === 0) return `0.${minutes}`
  let minutesResidue = minutes - (hours * HOURS_IN_MINUTES)
  minutesResidue = minutesResidue.toString().padStart(2, '0')
  return `${hours}.${minutesResidue}`
}

const ISO8601TimeFromDate = (date) => {
  let hours = date.getHours().toString().padStart(2, '0');
  let minutes = date.getMinutes().toString().padStart(2, '0');
  return `${hours}:${minutes}`;
}

function daysOfTheWeek(startDay = 'sunday') {
  const daysOfTheWeekData = [
    { translatedName: i18n.t('org_job_new_dialog.sun'), name: 'sunday' },
    { translatedName: i18n.t('org_job_new_dialog.mon'), name: 'monday' },
    { translatedName: i18n.t('org_job_new_dialog.tue'), name: 'tuesday' },
    { translatedName: i18n.t('org_job_new_dialog.wed'), name: 'wednesday' },
    { translatedName: i18n.t('org_job_new_dialog.thu'), name: 'thursday' },
    { translatedName: i18n.t('org_job_new_dialog.fri'), name: 'friday' },
    { translatedName: i18n.t('org_job_new_dialog.sat'), name: 'saturday' }]
  return changeDayOfTheWeekStartDay(daysOfTheWeekData, startDay)
}

function changeDayOfTheWeekStartDay(dayOfTheWeekArray, startDay = 'sunday') {
  const startDayIndex = dayOfTheWeekArray.findIndex((dayOfTheWeek) => dayOfTheWeek.name === startDay)
  if (startDayIndex === -1) return dayOfTheWeekArray

  for (let i = 0; i < startDayIndex; i++) {
    dayOfTheWeekArray.push(dayOfTheWeekArray.shift())
  }
  return dayOfTheWeekArray
}

function translateDay(dayName) {
  const result = daysOfTheWeek().find((day) => dayName === day.name) || {}
  return result.translatedName
}

function nextDay(dayName) {
  const dayIndex = DAYS.findIndex((day) => day === dayName)
  if (dayIndex === -1) return null

  return DAYS[(dayIndex + 1) % 7]
}

function previousDay(dayName) {
  const dayIndex = DAYS.findIndex((day) => day === dayName)
  if (dayIndex === -1) return null

  if (dayIndex === 0) return DAYS[DAYS.length - 1]
  return DAYS[(dayIndex - 1) % 7]
}

function getDayString(date = new Date()) {
  const dayIndex = date.getDay()
  return daysOfTheWeek()[dayIndex].name
}

function translateDayFromDate(date = new Date()) {
  const dayIndex = date.getDay()
  return daysOfTheWeek()[dayIndex].translatedName
}

function dateToISO8601String(dateObj) {
  const { dd, mm, yyyy } = paddedDate(dateObj)
  return toISO8601Date(`${dd}-${mm}-${yyyy}`)
}

function toISO8601Date(dateString) {
  const [day, month, year] = dateString.split('-')
  return `${year}-${month}-${day}`
}

function ISO8601StringToDate(iso8601String) {
  const [year, month, day] = iso8601String.split('-')
  const zeroBasedMonth = parseInt(month) - 1
  return new Date(year, zeroBasedMonth, day, 0, 0, 0, 0)
}

function isValidISO8601Date(iso8601String) {
  return isString(iso8601String) && iso8601String.length === 10
}

function isDateInTheFuture(otherDate) {
  const currentDate = new Date()
  currentDate.setHours(0, 0, 0, 0)
  const otherDateDup = new Date(otherDate.getTime())
  otherDateDup.setHours(0, 0, 0, 0)

  return otherDateDup.getTime() > currentDate.getTime()
}

function isDateIsToday(otherDate) {
  return dateToISO8601String(new Date()) === dateToISO8601String(otherDate)
}

function isDateIsYesterday(otherDate) {
  const yesterday = new Date()
  yesterday.setDate(yesterday.getDate() - 1)
  return dateToISO8601String(yesterday) === dateToISO8601String(otherDate)
}

function isDateInThePast(otherDate) {
  const currentDate = new Date()
  currentDate.setHours(0, 0, 0, 0)
  const otherDateDup = new Date(otherDate.getTime())
  otherDateDup.setHours(0, 0, 0, 0)

  return otherDateDup.getTime() < currentDate.getTime()
}

function getFirstDayOfWeek(date, firstDayOfWeekName = 'monday') {
  const firstDayOfWeekIndex = String(firstDayOfWeekName).toLowerCase() === 'sunday' ? 0 : 1
  const dayOfWeek = date.getDay()
  const firstDayOfWeek = new Date(date)
  const diff = dayOfWeek >= firstDayOfWeekIndex
    ? dayOfWeek - firstDayOfWeekIndex
    : 6 - dayOfWeek

  firstDayOfWeek.setDate(date.getDate() - diff)
  firstDayOfWeek.setHours(0, 0, 0, 0)

  return firstDayOfWeek
}

function dateRangeToDateArray({ startDate, endDate }) {
  if (!(endDate.getTime() > startDate.getTime())) return []

  const daysOfYear = []
  const startDateClone = new Date(startDate.getTime())
  // eslint-disable-next-line no-unmodified-loop-condition
  while (startDateClone <= endDate) {
    daysOfYear.push(new Date(startDateClone.getTime()))
    startDateClone.setDate(startDateClone.getDate() + 1)
  }
  return daysOfYear
}

function translateDayMap() {
  const result = {}
  daysOfTheWeek().forEach((day) => (result[day.name] = day.translatedName))
  return result
}

function translatedMonthAndYear(date = new Date()) {
  const month = date.toLocaleString ? date.toLocaleString(i18n.locale, { month: 'short' }) : `${date.getMonth() + 1} /`
  const year = date.getFullYear()
  return `${month} ${year}`
}

function translatedDayAndMonth(date = new Date()) {
  if (date.toLocaleString) return date.toLocaleString(i18n.locale, { month: 'long', day: 'numeric' })
  const dayInMonth = `${date.getDate()} /`
  const month = `${date.getMonth() + 1}`
  return `${dayInMonth} ${month}`
}

function dateToText({ fromDate = new Date(), countryCode = 'us' } = {}) {
  if (isDateIsToday(fromDate) === true) return i18n.t('today')
  if (isDateIsYesterday(fromDate) === true) return i18n.t('yesterday')

  return getClientDateFormatted({ fromDate, countryCode })
}

function ISO8601dateToText({ fromDate, countryCode = 'us' } = {}) {
  if (isValidISO8601Date(fromDate) !== true) return ''

  const dateObj = ISO8601StringToDate(fromDate)
  return dateToText({ fromDate: dateObj, countryCode })
}

function isDatesInSameMonth(fristDate, secondDate) {
  return fristDate.getFullYear() === secondDate.getFullYear() &&
    fristDate.getMonth() === secondDate.getMonth()
}

function dayNameFromISO8601String(iso8601String) {
  if (!isValidISO8601Date(iso8601String)) return null
  const date = ISO8601StringToDate(iso8601String)
  return getDayString(date)
}

function ISO8601dateTimeStringToISODate(iso8601DateTimeString) {
  if (isString(iso8601DateTimeString) !== true) return null
  const isoDate = iso8601DateTimeString.split('T')[0]
  return isValidISO8601Date(isoDate) ? isoDate : null
}

function dateToHHMMSSTime(date) {
  return date.toTimeString().split(' ')[0]
}

const isValidISO8601Time = (timeString) =>
  isString(timeString) && iso8601TimePattern.test(timeString);

// hh:mm:ss to 24 or 12 hour format
function timeStringToUserFormat({ timeString, localeCode }) {
  const dateTime = new Date('1970-01-01T' + timeString)
  return dateTime.toLocaleTimeString(localeCode, { timeStyle: 'short' })
}

const durationBetweenTimes = ({ startTime, endTime }) => {
  const [startHours, startMinutes] = startTime.split(':').map(Number)
  const [endHours, endMinutes] = endTime.split(':').map(Number)


  const startTotalMinutes = startHours * 60 + startMinutes
  const endTotalMinutes = endHours * 60 + endMinutes
  let durationMinutes = endTotalMinutes - startTotalMinutes
  if (durationMinutes < 0) {
    durationMinutes += 24 * 60
  }
  const durationHours = Math.floor(durationMinutes / 60)
  const durationRemainderMinutes = durationMinutes % 60
  return {
    hours: durationHours,
    minutes: durationRemainderMinutes
  }
}

const dateAndTimeStringToDate = ({ dateString, timeString, defaultTime = '23:59' }) => {
  const date = ISO8601StringToDate(dateString)
  const time = isValidISO8601Time(timeString) ? timeString : defaultTime
  const [hours, minutes] = time.split(':').map(Number)
  date.setHours(hours, minutes)
  return date
}


const diffBetweenDatesInHoursAndMinutes = (date1, date2) => {
  const diffInMs = Math.abs(date2 - date1);
  const hoursDiff = Math.floor(diffInMs / (1000 * 60 * 60));
  const minutesDiff = Math.floor((diffInMs % (1000 * 60 * 60)) / (1000 * 60));
  return { hours: hoursDiff, minutes: minutesDiff }
}

const translateHoursAndMinutes = ({ hours, minutes, maxHours = 72 }) => {
  if (hours > 0) {
    if (hours >= maxHours) {
      const days = Math.floor(hours / HOURS_IN_DAY)
      if (days > 60) {
        const months = Math.floor(days / AVG_DAYS_IN_MONTH)
        if (months > 24) {
          const years = Math.floor(months / MONTHS_IN_YEAR)
          return i18n.t('x_years', { years })
        }
        return i18n.t('x_months', { months })
      } else {
        return i18n.t('x_days', { days })
      }
    }
    if (minutes > 0) return i18n.t('x_hour_and_minutes', { hours, minutes })
    return i18n.t('x_hours', { hours })
  }
  if (minutes > 0) return i18n.t('x_minutes', { minutes })
  return ''
}

const getCurrentLocalISODate = (date = new Date()) => {
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const day = String(date.getDate()).padStart(2, '0');
  const hours = String(date.getHours()).padStart(2, '0');
  const minutes = String(date.getMinutes()).padStart(2, '0');
  const seconds = String(date.getSeconds()).padStart(2, '0');

  return `${year}-${month}-${day}T${hours}:${minutes}:${seconds}`;
}

export {
  getClientDate,
  clientDateToDate,
  fromMinutesToHoursAndMinutes,
  daysOfTheWeek,
  translateDay,
  isDateInTheFuture,
  isDateIsToday,
  isDateInThePast,
  getDayString,
  translateDayFromDate,
  toISO8601Date,
  ISO8601StringToDate,
  dateToISO8601String,
  nextDay,
  previousDay,
  getClientDateFormatted,
  isValidISO8601Date,
  getFirstDayOfWeek,
  dateRangeToDateArray,
  translateDayMap,
  translatedMonthAndYear,
  translatedDayAndMonth,
  dateToText,
  ISO8601dateToText,
  isDatesInSameMonth,
  dayNameFromISO8601String,
  ISO8601dateTimeStringToISODate,
  dateToHHMMSSTime,
  timeStringToUserFormat,
  isValidISO8601Time,
  durationBetweenTimes,
  ISO8601TimeFromDate,
  dateAndTimeStringToDate,
  diffBetweenDatesInHoursAndMinutes,
  translateHoursAndMinutes,
  getCurrentLocalISODate
}
